import { constantRoutes } from "../../constants/constantRoutes"
import { navigate } from "../../Route/navigationServices"
import ApiConstants from "../../themes/apiConstants"
import ValidationConstants from "../../themes/validationConstants"
import { put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { AddMember, bloackApi, changeRoleApi, deleteUserApi, editApi, interestListApi, subscriptionApi, userAddressApi, userListApi, viewApi } from "../axios/axiosApi"
import { sessionOut } from "./authSaga"

function* userListSaga(action) {
    try {
        let response = yield call(userListApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_USERLIST_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* intrestListSaga(action) {
    try {
        let response = yield call(interestListApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_INTEREST_LIST_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* viewSaga(action) {
    try {
        let response = yield call(viewApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_VIEW_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* editSaga(action) {
    try {
        let response = yield call(editApi, action.payload[0])
        let { result, status } = response

        if (status === 1) {
            navigate(constantRoutes.usermanagement)
            toast(result?.message, { toastId: 'success-error' })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* blockSaga(action) {
    try {
        let response = yield call(bloackApi, action.payload[0])
        let { result, status } = response

        if (status === 1) {
            yield call(userListSaga, { payload: action.payload[1] })
            toast(result?.message)
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* subscriptionSaga(action) {
    try {
        let response = yield call(subscriptionApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_SUBSCRIPTION_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* userAddressSaga(action) {
    try {
        let response = yield call(userAddressApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_USER_ADDRESS_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}


function* changeRoleSaga(action) {
    try {
        let response = yield call(changeRoleApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.CHANGE_USER_ROLE_SUCCESS,
                payload: action.payload
            })
            toast(result?.message, { toastId: 'userList-change-success' })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* addSaga(action) {
    try {
        let response = yield call(AddMember, action.payload)
        let { result, status } = response

        if (status === 1) {
            navigate(constantRoutes.usermanagement)
            toast(result?.message, { toastId: 'success-error' })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* deleteSaga(action) {
    try {
        let response = yield call(deleteUserApi, action.payload[0])
        let { result, status } = response

        if (status === 1) {
            yield call(userListSaga, { payload: action.payload[1] })
            toast(result?.message)
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}


export default function* rootUserSaga() {
    yield takeLatest(ApiConstants.API_USERLIST_LOAD, userListSaga)
    yield takeLatest(ApiConstants.API_INTEREST_LIST_LOAD, intrestListSaga)
    yield takeLatest(ApiConstants.API_VIEW_LOAD, viewSaga)
    yield takeLatest(ApiConstants.API_EDIT_LOAD, editSaga)
    yield takeLatest(ApiConstants.API_ADD_LOAD, addSaga)
    yield takeLatest(ApiConstants.API_DELETE_USER_LOAD, deleteSaga)
    yield takeLatest(ApiConstants.API_BLOCK_LOAD, blockSaga)
    yield takeLatest(ApiConstants.API_USER_ADDRESS_LOAD, userAddressSaga)
    yield takeLatest(ApiConstants.API_SUBSCRIPTION_LOAD, subscriptionSaga)
    yield takeLatest(ApiConstants.CHANGE_USER_ROLE_LOAD, changeRoleSaga)
}
import pluss from '../images/plus.png'
import login from '../images/logo.svg'
import login2 from '../images/logo2.svg'
import userLastWeek from '../images/user-week.png'
import userMonth from '../images/user-month.png'
import userQuater from '../images/user-quater.png'
import user6months from '../images/user-6months.png'
import userYear from '../images/user-year.png'
import eventLastWeek from '../images/event-week.png'
import eventMonth from '../images/event-month.png'
import eventQuater from '../images/event-quater.png'
import event6months from '../images/event-6months.png'
import eventYear from '../images/event-year.png'
import edit from '../images/user.png'
import vector1 from '../images/vector1.png'
import addImage from '../images/addImage.png'
import monthly_dollar from '../images/monthly.png'
import Annually from '../images/Annually.png'
import today from '../images/today.png'
import dollar from '../images/dollar.png'
import PageNotFound from "../images/404.png"

const images = {
  pluss: pluss,
  login: login,
  login2: login2,
  userLastWeek: userLastWeek,
  userMonth: userMonth,
  userQuater: userQuater,
  user6months: user6months,
  userYear: userYear,
  eventLastWeek: eventLastWeek,
  eventMonth: eventMonth,
  eventQuater: eventQuater,
  event6months: event6months,
  eventYear: eventYear,
  edit: edit,
  vector1: vector1,
  addImage: addImage,
  monthly_dollar: monthly_dollar,
  Annually: Annually,
  today: today,
  dollar: dollar,
  PageNotFound: PageNotFound
}
export default images


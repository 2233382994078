const ValidationConstants = {
  price: "Please enter price.",
  fileSize: "Image size should be up to 10 MB.",
  clipDuration: "Video length should be of 2 minutes only.",
  nameMin: "Name should be at least 3 character long.",
  name: "Please enter name.",
  nameLimit: "Can not enter more than 50 character.",
  age: "Please enter valid age.",
  emptyAge: "Please enter age.",
  contactNumber: "Contact number should be at least 8 to 15 digits.",
  invalidNumber: "Please enter valid Contact number.",
  emptyNumber: "Please enter contact number.",
  fileExtention: "Please upload .png, .jpg and .jpeg image format only.",
  videoExtension: "Please upload .mp4 format only.",
  emptyPassword: "Please enter password.",
  internetCheck: "Please check your internet connection.",
  invalidPassword: "Password should not contain whitespaces.",
  enterValidPassword: "Please enter Valid Password.",
  invalidEmail: "Please enter valid email address.",
  emptyEmail: "Please enter email address.",
  emailLimit: "Maximum 320 characters are allowed.",
  shortPassword: "New password should be at least 8 characters long.",
  emptyConfirm: "Please enter confirm password.",
  invalidConfirmPassword:
    "New password and confirm new password should be same.",
    invalidConfirm_Password:
    "Password and Confirm password should be same.",
  emptyOldPassword: "Please enter old password.",
  emptyNewPassword: "Please enter new password.",
  emptyConfirmPassword: "Please enter confirm new password.",
  notSamePassword: "Confirm new password must be same as new password.",
  oShortPassword: "Old password should be at least 8 characters long.",
  endDate: "Please enter valid event end date.",
  durationVALID: "Please enter select event valid duration time.",
  duration: "Please enter select event duration time.",
  endDateRequired: "Please enter event end date.",
  eventNameRequired: "Please enter event name.",
  userNameRequired: "Please enter user name.",
  addressRequired: "Please enter address.",
  descriptionRequired: "Please enter description.",
  promoDescriptionRequired: "Please enter promo description.",
  descriptionLimit: "Description can be up-to 50 words.",
  startTimeRequired: "Please enter event start time.",
  startDateRequired: "Please enter event start Date.",
  titleRequired: "Please enter title.",
  NA_city: "We will be available in your state soon.",
  minimunMedia: "Please at least upload 1 image or video.",
  profileRequired: "Please upload profile image.",
  somethingWorng: "Something went worng.",
};

export default ValidationConstants;

import React from "react"
import { Breadcrumb } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { constantRoutes } from "../constants/constantRoutes";

const BreadCrumb = (props) => {
    const { firstCrumb, secondCrumb, currentCrumb } = props
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={constantRoutes.dashboard}><FontAwesomeIcon icon={faHome} /></Link>
            </Breadcrumb.Item>
            {firstCrumb && <Breadcrumb.Item>
                <Link to={firstCrumb?.link}>{firstCrumb?.pathName}</Link>
            </Breadcrumb.Item>}
            {secondCrumb && <Breadcrumb.Item >
                <Link to={secondCrumb?.link}>{secondCrumb?.pathName}</Link>
            </Breadcrumb.Item>}
            <Breadcrumb.Item>{currentCrumb}</Breadcrumb.Item>
        </Breadcrumb >
    )
}

export default BreadCrumb;
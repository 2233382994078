
import React from "react";
import images from "../themes/appImage";

export const PageNotFound = () => {
    return (
        <div className="main-div-PageNotFound">
            <img className="pageNotFound" src={images.PageNotFound} />
        </div>
    )
}
import { DataManagersKeys } from './dataManagersKeys'

export const DataManager = {
    setAccessToken(token) {
        return localStorage.setItem(DataManagersKeys.access_token, token)
    },
    getAccessToken() {
        return localStorage.getItem(DataManagersKeys.access_token)
    },
    setUserData(data) {
        return localStorage.setItem(DataManagersKeys.userData, data)
    },
    getUserData() {
        return localStorage.getItem(DataManagersKeys.userData)
    },
    removeData() {
        localStorage.clear()
    },
    setUserType(key) {
        return localStorage.setItem(DataManagersKeys.type, key)
    },
    getUserType() {
        return localStorage.getItem(DataManagersKeys.type)
    },
}


import React, { useState } from "react";
import { Modal } from "antd";
import appconstant from "../themes/appconstant";
import { usePlacesWidget } from "react-google-autocomplete";


const GooglePlacesAutoComplete = ({ onPlaceSelected, setIsVisible, isVisible }) => {
    const [places, setPlaces] = useState(null);
    return isVisible && (
        <Modal
            maskClosable={false}
            centered
            title={'Search ' + appconstant.address}
            className="forgot-success"
            visible={true}
            onCancel={() => setIsVisible(false)}
            onOk={() => {
                if (places) {
                    onPlaceSelected(places)
                    setIsVisible(false)
                }
            }
            }
        >
            <div>
                <div className="form-group">
                    <div className='input_fields'>
                        <InputContainer setPlaces={setPlaces} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const InputContainer = ({ setPlaces }) => {

    const { ref } = usePlacesWidget({
        apiKey: 'AIzaSyBR5DtuOkvV9FBGFBUrZPBzxZgPwN0TsdM',
        options: {
            types: ["geocode", "establishment"],
        },
        onPlaceSelected: (place) => setPlaces(place)
    })

    return <input
        type='text'
        placeholder={'Search ' + appconstant.address}
        className='form-control'
        ref={ref}
        maxLength={1000}
    />
}


export default GooglePlacesAutoComplete;

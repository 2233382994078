import { combineReducers } from 'redux';
import authenticationReducer from './authReducer';
import userManagementReducer from './userReducer';
import eventReducer from './eventReducer';
import postReducer from './postReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
    authenticationReducer, userManagementReducer, eventReducer, postReducer, dashboardReducer
});

export default rootReducer;
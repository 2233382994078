import ApiConstants from "../../themes/apiConstants"

export const postDetailsAction = payload => {
    return {
        type: ApiConstants.API_POST_DETAILS_LOAD,
        payload
    }
}

export const postUpdateAction = payload => {
    return {
        type: ApiConstants.API_POST_UPDATE_LOAD,
        payload
    }
}

export const postListAction = payload => {
    return {
        type: ApiConstants.API_POST_LIST_LOAD,
        payload
    }
}

export const postBlockAction = payload => {
    return {
        type: ApiConstants.API_POST_BLOCK_LOAD,
        payload
    }
}

export const postDeleteAction = payload => {
    return {
        type: ApiConstants.API_DELETE_POST_LOAD,
        payload
    }
}
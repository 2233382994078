import React, { useEffect } from 'react'
import images from '../../themes/appImage'
import 'react-toastify/dist/ReactToastify.css'
import ValidationConstants from '../../themes/validationConstants'
import {
  resetAction,
  resetPasswordLinkAction
} from '../../redux/actions/authActions.js'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ExpiredToken from '../../common/expiredToken'
import { toast } from 'react-toastify'
import Textfield from '../../common'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isInternetConnected } from '../../helper/networkUtils'
import appconstant from '../../themes/appconstant'
import { capitalizeFirstLetter, whitespacesRgx } from '../../utils/validator'
import { ValidationFunction } from '../../themes/validationFunctions'
import { constValues } from '../../constants/constantValues'


const ResetPassword = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state.authenticationReducer)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  useEffect(() => {
    if (token) {
      dispatch(resetPasswordLinkAction({ token: token }))
    }
  }, [])

  const onSubmit = ({ confirmPassword }) => {
    if (isInternetConnected()) {
      dispatch(resetAction({ token: token, password: confirmPassword }))

    } else {
      toast(ValidationConstants.internetCheck)
    }
  }
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.NewPassword)))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,ValidationFunction.password(capitalizeFirstLetter(appconstant.NewPassword)))
      .min(constValues.passMinLength, ValidationFunction.minlength(capitalizeFirstLetter(appconstant.NewPassword), constValues.passMinLength))
      .required(ValidationFunction.emptyField(capitalizeFirstLetter(appconstant.NewPassword))),
    confirmPassword: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.ConfirmNewPassword)))
      .required(ValidationFunction.emptyField(capitalizeFirstLetter(appconstant.ConfirmNewPassword)))
      .oneOf(
        [Yup.ref('password'), null],
        ValidationConstants.invalidConfirmPassword
      )
  })
  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    onSubmit
  })

  if (!selector.onLoad) {
    return <ExpiredToken props={selector} />
  } else {
    if (selector?.userData) {
      return <ExpiredToken props={selector} />
    } else {
      return (
        <div className='image-bg'>
          <div className='page-header'>
            <div className='main-div'>
              <div className='login-form'>
                <div>
                  <img src={images.login} />
                </div>
                <h3>{appconstant.resetPassword}</h3>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className='user'>
                    <Textfield
                      onBlur={formik.handleBlur}
                      error={formik.errors.password}
                      touched={formik.touched.password}
                      value={formik.values.password.trim()}
                      onChange={formik.handleChange('password')}
                      type={appconstant.Password.toLocaleLowerCase()}
                      placeholder={capitalizeFirstLetter(appconstant.NewPassword)}
                    />
                  </div>
                  <div className='user'>
                    <Textfield
                      placeholder={capitalizeFirstLetter(appconstant.ConfirmNewPassword)}
                      onChange={formik.handleChange('confirmPassword')}
                      type={appconstant.Password.toLocaleLowerCase()}
                      value={formik.values.confirmPassword.trim()}
                      touched={formik.touched.confirmPassword}
                      error={formik.errors.confirmPassword}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div>
                    <button className='login-link' type='submit'>
                      {appconstant.Rest}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ResetPassword

import React, { useEffect } from "react";
import images from "../../themes/appImage";
import { Image } from "antd";
import appconstant from "../../themes/appconstant";
import Textfield from "../../common";
import { navigate } from "../../Route/navigationServices";
import { constantRoutes } from "../../constants/constantRoutes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ValidationConstants from "../../themes/validationConstants";
import { isInternetConnected } from "../../helper/networkUtils";
import { time_dateFormat } from "../../constants/constantValues";
import moment from "moment-timezone";
import { url } from "../../redux/axios/apikit";
import { viewEventAction } from "../../redux/actions/eventActions.js";
import BreadCrumb from "../../common/breadCrumb";
import { userAddressAction } from "../../redux/actions/userActions";
import TextAreafield from "../../common/TextAreaField";

const EventDetails = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.eventReducer.eventViewData);
  const locations = useSelector(
    (state) => state.userManagementReducer?.location
  );

  const state = locations?.filter((value) => value?._id === data?.state?._id);
  const city = state?.[0]?.cities?.filter(
    (value) => value?._id === data?.city?._id
  )?.[0]?.name;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isInternetConnected()) {
      dispatch(
        viewEventAction({
          eventId: JSON.parse(localStorage.getItem("eventId")),
        })
      );
      dispatch(userAddressAction());
    } else {
      toast(ValidationConstants.internetCheck, { id: "eventDetailsEdit" });
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        firstCrumb={{
          link: constantRoutes.eventmanagement,
          pathName: appconstant.eventManagement,
        }}
        currentCrumb={appconstant.eventDetails}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.eventDetails}
            </h6>
          </div>
          <div className="profile-image">
            <Image src={data?.image ? url + data?.image : images.edit} />
          </div>
          <div className="wrapper_line view_modules view ">
            <div className="form-group">
              <label>{appconstant.eventName}</label>
              <Textfield
                className="form-control"
                type="text"
                placeholder={appconstant.eventName}
                value={data?.name}
                disabled
              />
            </div>
            <div>
              <div className="form-group">
                <label>{appconstant.eventStartDate}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.eventStartDate}
                  value={moment(data?.startDate)
                    .local()
                    .format(time_dateFormat.dateFormat)}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.eventStartTime}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.eventStartTime}
                  value={moment(data?.startTime)
                    .local()
                    .format(time_dateFormat.timeFormat)}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.TimeDuration}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.TimeDuration}
                  value={data?.duration}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.description}</label>
                <TextAreafield
                  className="textArea-control"
                  type="text"
                  placeholder={appconstant.description}
                  value={data?.description}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.eventFor}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.address}
                  value={data?.visibleTo == "paid"?"Members":data?.visibleTo[0].toUpperCase() +
                    data?.visibleTo.slice(1) || "Members"}
                  disabled
                />
              </div>

              <div className="form-group">
                <label>{appconstant.address}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.address}
                  value={data?.address}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="up-btn going">
            <button
              onClick={() => navigate(constantRoutes.eventparticipants)}
              type="submit"
              className="button-list "
            >
              {appconstant.going}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventDetails;

import ApiConstants from "../../themes/apiConstants"

export const viewAction = payload => {
    return { type: ApiConstants.API_VIEW_LOAD, payload }
}

export const editAction = payload => {
    return { type: ApiConstants.API_EDIT_LOAD, payload }
}

export const interestListAction = payload => {
    return { type: ApiConstants.API_INTEREST_LIST_LOAD, payload }
}

export const userAddressAction = payload => {
    return {
        type: ApiConstants.API_USER_ADDRESS_LOAD,
        payload
    }
}

export const userListAction = payload => {
    return { type: ApiConstants.API_USERLIST_LOAD, payload }
}

export const blockAction = payload => {
    return { type: ApiConstants.API_BLOCK_LOAD, payload }
}


export const changeRoleAction= payload =>{
    return {
      type:ApiConstants.CHANGE_USER_ROLE_LOAD,
      payload
    }
  }

  export const addAction= payload =>{
    return {
      type:ApiConstants.API_ADD_LOAD,
      payload
    }
  }

  export const deleteUserAction= payload=>{
    return{
        type:ApiConstants.API_DELETE_USER_LOAD,
        payload
    }
  }
import React, { useEffect } from 'react'
import { Table, } from 'antd'
import appconstant from '../../themes/appconstant'
import { toast } from 'react-toastify'
import ValidationConstants from '../../themes/validationConstants'
import { useDispatch, useSelector } from 'react-redux'
import { isInternetConnected } from '../../helper/networkUtils'
import { constantRoutes } from '../../constants/constantRoutes'
import Textfield from '../../common/index'
import { eventParticipantsAction } from '../../redux/actions/eventActions.js'
import { pageDetails } from '../../redux/actions/authActions.js'
import BreadCrumb from '../../common/breadCrumb'
import moment from 'moment'

const EventParticipants = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.eventReducer.participants)
  const pageDetail = useSelector(
    state => state.authenticationReducer.pageDetials
  )

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'srno',
      key: 'srno',
      sorter: false,
      render: (value, item, index) => index + 1,
      width: '200px'
    },
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: value => (value ? value : 'N/A'),
      ellipsis: 'true',
      align: 'left'
    }
  ]

  const onSearch = e => {
    const order = e?.order === 'descend' ? -1 : e?.order === 'ascend' ? 1 : ''
    if (isInternetConnected()) {
      dispatch(
        eventParticipantsAction({
          eventId: JSON.parse(localStorage.getItem('eventId')),
          sort: 'name',
          order: order,
          search: e?.search
        })
      )
      dispatch(
        pageDetails({
          current: 1,
          offset: 0,
          order: 'name',
          search: e?.search,
          sort: order ? e.order : '',
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 4 })
    }
  }

  useEffect(() => {
    window.scroll(0, 0)
    if (isInternetConnected()) {
      dispatch(
        eventParticipantsAction({
          eventId: JSON.parse(localStorage.getItem('eventId')),
          sort: 'name',
          order: '',
          search: ''
        })
      )
      dispatch(
        pageDetails({
          limit: 10,
          offset: 0,
          sort: 'name',
          search: '',
          order: '',
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 4 })
    }
  }, [])

  return (
    <div>
      <BreadCrumb firstCrumb={{ link: constantRoutes.eventmanagement, pathName: appconstant.eventManagement }} secondCrumb={{ link: constantRoutes.eventdetails, pathName: appconstant.eventDetails }} currentCrumb={appconstant.EventParticipants} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.EventParticipants}
            </h6>
            <Textfield
              type='text'
              className='iocn-search search-bar'
              placeholder={appconstant.search}
              onChange={e => onSearch({ search: e.target.value })}
            />
          </div>
          <Table
            dataSource={data}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
            onChange={(e, _, value) => onSearch({ order: value?.order })}
            scroll={{
              y: 300
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default EventParticipants

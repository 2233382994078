import ApiConstants from '../../themes/apiConstants'

const initialState = {
    onLoad: true,
    userData: null,
    postDetail: [],
    data: null,
    viewUser: null,
    eventData: null,
    eventViewData: null,
    interestData: null
}

function eventReducer(state = initialState, action) {
    switch (action.type) {

        ////Event List details........
        case ApiConstants.API_EVENTLIST_SUCCESS:
            return { ...state, eventData: action.payload }

        ////Event View Details........
        case ApiConstants.API_EVENTVIEW_SUCCESS:
            return { ...state, eventViewData: action.payload }

        case ApiConstants.API_PARTICIPANTS_SUCCESS:
            return { ...state, participants: action.payload }

        ////TimeZone......
        case ApiConstants.API_TIMEZONE_SUCCESS:
            return { ...state, timezones: action.payload }

        default:
            return state
    }
}

export default eventReducer

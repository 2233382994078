import React from 'react'
import { Layout, Menu, Modal } from 'antd'
import { useLocation } from 'react-router'
import Icon, {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeFilled,
  LockFilled,
  LogoutOutlined,
  MailFilled,
  CalendarOutlined,
  UserOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useNavigate } from 'react-router-dom'
import images from '../themes/appImage'
import { isInternetConnected } from '../helper/networkUtils'
import ValidationConstants from '../themes/validationConstants'
import { constantRoutes } from '../constants/constantRoutes'
import appconstant from '../themes/appconstant'
import { logoutAction } from '../redux/actions/authActions.js'
import { DataManager } from '../helper/dataManager'



const { Sider } = Layout

const Sidebar = props => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()
  let type = DataManager.getUserType()
  const logoutModel = record => {
    Modal.confirm({
      title: appconstant.logout,
      content: `Are you sure, you want to logout?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        if (isInternetConnected()) {
          dispatch(logoutAction())
        } else {
          toast(ValidationConstants.internetCheck, { toastId: 0 })
        }
      },
      onCancel() { },
      className: 'new-button'
    })
  }

  const onMenuClick = e => {
    if (e.key === constantRoutes.login) {
      logoutModel()
    } else {
      navigate(e.key)
    }
  }

  let activeKey = ''
  switch (location.pathname) {
    case constantRoutes.dashboard:
      activeKey = constantRoutes.dashboard
      break
    case constantRoutes.changepassword:
      activeKey = constantRoutes.changepassword
      break
    case constantRoutes.usermanagement:
      activeKey = constantRoutes.usermanagement
      break
    case constantRoutes.userdetails:
      activeKey = constantRoutes.usermanagement
      break
    case constantRoutes.edituserdetails:
      activeKey = constantRoutes.usermanagement
      break
    case constantRoutes.addEvent:
      activeKey = constantRoutes.eventmanagement
      break
    case constantRoutes.eventmanagement:
      activeKey = constantRoutes.eventmanagement
      break
    case constantRoutes.eventparticipants:
      activeKey = constantRoutes.eventmanagement
      break
    case constantRoutes.eventdetails:
      activeKey = constantRoutes.eventmanagement
      break
    case constantRoutes.editEventDetails:
      activeKey = constantRoutes.eventmanagement
      break
    case constantRoutes.postmanagement:
      activeKey = constantRoutes.postmanagement
      break
    case constantRoutes.postdetails:
      activeKey = constantRoutes.postmanagement
      break
    case constantRoutes.editPostDetails:
      activeKey = constantRoutes.postmanagement
      break
  }
  return (
    <Sider
      className={props.isVisible ? 'sider_left new closedd' : 'sider_left new'}
      width={props.isVisible ? '107' : '253'}
    >
      <div className='logo'>
        <Link className='sider-a' to={constantRoutes.dashboard}>
          <img src={images.login2} />
        </Link>
      </div>
      <div
        className='admin-text'
        style={{
          display: props.isVisible ? 'none' : 'unset'
        }}
      >
        <h2>Admin Panel</h2>
      </div>
        {type == "admin"?(
          <Menu
          theme='dark'
          mode='inline'
          defaultSelectedKeys={['1']}
          defaultOpenKeys={[activeKey]}
          selectedKeys={[activeKey]}
          onClick={e => onMenuClick(e)}
          tabIndex='-1'
        >
          <Menu.Item key={constantRoutes.dashboard}>
          <Icon component={HomeFilled} />
          <span className='line_dash'>{appconstant.dashboard}</span>
        </Menu.Item>
        <Menu.Item key={constantRoutes.usermanagement}>
          <Icon component={UserOutlined} />
          <span className='line_dash'>{appconstant.UserManagement}</span>
        </Menu.Item>
        <Menu.Item key={constantRoutes.eventmanagement}>
          <Icon component={CalendarOutlined} />
          <span className='line_dash'>{appconstant.eventManagement}</span>
        </Menu.Item>
        <Menu.Item key={constantRoutes.postmanagement}>
          <Icon component={MailFilled} />
          <span className='line_dash'>{appconstant.postManagement}</span>
        </Menu.Item>
        <Menu.Item key={constantRoutes.changepassword}>
          <Icon component={LockFilled} />
          <span className='line_dash'>{appconstant.changepassword}</span>
        </Menu.Item>
        <Menu.Item key={constantRoutes.login}>
          <Icon component={LogoutOutlined} />
          <span className='line_dash'>{appconstant.logout}</span>
        </Menu.Item>
         </Menu>)
        :
       ( <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={e => onMenuClick(e)}
        tabIndex='-1'
      >
        <Menu.Item key={constantRoutes.dashboard}>
        <Icon component={HomeFilled} />
        <span className='line_dash'>{appconstant.dashboard}</span>
      </Menu.Item>
      <Menu.Item key={constantRoutes.eventmanagement}>
        <Icon component={CalendarOutlined} />
        <span className='line_dash'>{appconstant.eventManagement}</span>
      </Menu.Item>
      <Menu.Item key={constantRoutes.changepassword}>
        <Icon component={LockFilled} />
        <span className='line_dash'>{appconstant.changepassword}</span>
      </Menu.Item>
      <Menu.Item key={constantRoutes.login}>
        <Icon component={LogoutOutlined} />
        <span className='line_dash'>{appconstant.logout}</span>
      </Menu.Item>
       </Menu>)
  }
   <div className='angle-btn'>
        <button type='submit' onClick={props.handlewClick} className=' btn '>
          <Icon
            component={
              !props.isVisible ? ArrowLeftOutlined : ArrowRightOutlined
            }
          />
        </button>
      </div>
    </Sider>
  )
}
export default Sidebar

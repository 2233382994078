import React, { useState, useEffect, useRef } from 'react'
import images from '../../themes/appImage'
import { Image } from 'antd'
import appconstant from '../../themes/appconstant'
import {
  editAction,
  interestListAction,
  userAddressAction,
  viewAction
} from '../../redux/actions/userActions.js'
import ValidationConstants from '../../themes/validationConstants'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isInternetConnected } from '../../helper/networkUtils'
import moment from 'moment'
import { time_dateFormat, extentions } from '../../constants/constantValues'
import MultiSelect from '../../common/multiSelect'
import { useDispatch, useSelector } from 'react-redux'
import Textfield from '../../common'
import { url } from '../../redux/axios/apikit'
import { constantRoutes } from '../../constants/constantRoutes'
import {
  ValidationFunction
} from '../../themes/validationFunctions'
import { DropDownSelect } from '../../common/DropDownSelect'
import { capitalizeFirstLetter } from '../../utils/validator'
import BreadCrumb from '../../common/breadCrumb'

const EditUserDetails = () => {
  const [selectedItems, setSelectedItems] = useState([])
  const [image, setImage] = useState()
  const [detail, setDetail] = useState({})
  const refs = useRef()
  const dispatch = useDispatch()

  const data = useSelector(state => state.userManagementReducer?.viewUser)
  const locations = useSelector(state => state.userManagementReducer?.location)
  const interestList = useSelector(
    state => state.userManagementReducer?.interestData
  )

  const susbcribed = useSelector(
    state => state.authenticationReducer?.subcribed
  )
  const state = locations?.filter(value => value?._id === detail?.state?._id)?.[0]
    ?.name
  const cities = locations?.filter(value => {
    if (value?._id === detail?.state?._id) {
      return value?.cities
    }
  })?.[0]?.cities

  const file = () => {
    refs.current.click()
  }
  const imageSet = e => {
    if (
      e.target.files[0]?.type === extentions.jpeg ||
      e.target.files[0]?.type === extentions.png ||
      e.target.files[0]?.type === extentions.jpg
    ) {
      if (e.target.files[0]?.size < 10485760) {
        setImage(e.target.files[0])
      } else {
        toast(ValidationConstants.fileSize)
      }
    } else {
      toast(ValidationConstants.fileExtention)
    }
  }
  const multiSelect = value => {
    setSelectedItems(value)
    setDetail(state => {
      return {
        ...state,
        intrest: value
      }
    })
  }
  const onChange = obj => {

    setDetail(state => {
      return {
        ...state, ...obj
      }
    })
  }
  const validate = msg => {
    const error = {}
    if (detail?.name === '' || !detail?.name) {
      error.name = ValidationFunction.emptyField(appconstant.userName.toLocaleLowerCase())
    } else if (detail?.name?.[0] === ' ') {
      error.name = ValidationFunction.validField(appconstant.userName.toLocaleLowerCase())
    } else if (detail?.name?.length < 3) {
      error.name = ValidationFunction.minlength('User name')
    }
    if (detail?.location === '') {
      error.location = ValidationFunction.emptyField(appconstant.address.toLocaleLowerCase())
    } else if (detail?.location?.[0] === ' ') {
      error.location = ValidationFunction.validField(appconstant.userName.toLocaleLowerCase())
    } else if (detail?.location?.length < 3) {
      error.location = ValidationFunction.minlength(appconstant.address)
    }
    // if (!cities?.length && state) {
    //   error.city = ValidationConstants.NA_city
    // }
    // if (!detail?.state) {
    //   error.state = ValidationFunction.plzSelect(appconstant.state.toLocaleLowerCase())
    // }
    if (!detail?.intrest?.length) {
      error.intrest = ValidationFunction.plzSelect(appconstant.interest.toLocaleLowerCase())
    }
    return error
  }
  const update = () => {
    if (!Object.values(validate()).join()) {
      const body = new FormData()
      body.append('name', detail?.name)
      body.append('userId', JSON.parse(localStorage.getItem('userId')))

      body.append('intrest', JSON.stringify(selectedItems))
      body.append('image', image)
      if (isInternetConnected()) {
        if (image || data?.profileImage) {
          dispatch(editAction([body, window.location.pathname]))
        } else {
          toast(ValidationConstants.profileRequired, { toastId: 22 })
        }
      } else {
        toast(ValidationConstants.internetCheck, {
          toastId: 'eventDetailsEdit'
        })
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isInternetConnected()) {
      dispatch(interestListAction(JSON.parse(localStorage.getItem('userId'))))
      dispatch(viewAction(JSON.parse(localStorage.getItem('userId'))))
      dispatch(userAddressAction())
    } else {
      toast(ValidationConstants.internetCheck, { id: 'viewuser-index' })
    }
  }, [])
  useEffect(() => {
    const selected = interestList?.filter(value => value?.isSelected === true)
    setSelectedItems(selected?.map(value => value?._id))
    setDetail(data)
  }, [data, interestList, susbcribed])

  return (
    <div>
      <BreadCrumb firstCrumb={{ link: constantRoutes.usermanagement, pathName: appconstant.UserManagement }} currentCrumb={appconstant.editDetails} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.editUserDetails}
            </h6>
          </div>
          <div className='profile-image'>
            <Image
              preview={false}
              type='image'
              src={
                image
                  ? URL.createObjectURL(image)
                  : data?.profileImage
                    ? url + data?.profileImage
                    : images.edit
              }
              className={image && 'upload_image'}
            />
            <div className='datacenter-icon'>
              <img
                title={ValidationFunction.clickToImg(
                  image || data?.profileImage
                    ? appconstant.change
                    : appconstant.upload
                )}
                src={images.pluss}
                onClick={file}
              />
              <input
                ref={refs}
                type='file'
                onChange={imageSet}
                style={{ display: 'none' }}
                accept={Object.values(extentions)}
                onClick={e => (e.target.value = null)}
              />
            </div>
          </div>
          <div className='wrapper_line view_modules view '>
            <div className='form-group'>
              <label>{appconstant.userName}</label>
              <Textfield
                className='form-control'
                type='text'
                placeholder={appconstant.userName}
                onChange={e => onChange({ name: e.target.value.trimLeft() })}
                value={detail?.name}
                error={validate()?.name}
                maxLength={50}
              />
            </div>
            <div>
              <div className='form-group'>
                <label>{appconstant.EmailAddress}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.EmailAddress}
                  value={data?.email}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.dateOfJoining}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.dateOfJoining}
                  value={moment(data?.createdAt).format(time_dateFormat.dateFormat)}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.dateOfBirth}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.dateOfBirth}
                  value={
                    data?.dob
                      ? moment(data?.dob).format(time_dateFormat.dateFormat)
                      : appconstant.NA
                  }
                  disabled
                />
              </div>

              <div className='form-group'>
                <label>{appconstant.interest}</label>
                <MultiSelect
                  value={selectedItems}
                  disabled={false}
                  showArrow={true}
                  onChange={multiSelect}
                  OPTIONS={interestList}
                  error={validate().intrest}
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.gender}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.gender}
                  value={capitalizeFirstLetter(data?.gender) || appconstant.NA}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.noOfPosts}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.noOfPosts}
                  value={data?.posts}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.noOfFriend}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.noOfFriend}
                  value={data?.friends}
                  disabled
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.member}</label>
                <Textfield
                  className='form-control'
                  value={appconstant.packages[0] == detail?.membership?.name ? "Frendii Social"
                    : appconstant.packages[1] == detail?.membership?.name ? "Frendii Social Plus"
                      : appconstant.packages[2] == detail?.membership?.name ? "Frendii Wellness"
                        : appconstant.NA
                  }
                  placeholder={appconstant.member}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='up-btn'>
            <button onClick={update} type='submit' className='button-list'>
              {appconstant.buttonupate}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditUserDetails

let navigationRef = null
let pathNameRef = null

export const setNavigate = (data, pathName) => {
  navigationRef = data
  pathNameRef = pathName
}

export const navigate = (path, params = null) => {
  if (params) {
    return navigationRef(path, { state: params })
  }
  return navigationRef(path)
}

export const goBack = () => {
  navigationRef(-1)
}

export const getCurrentRoute = (name, index) => {
  return pathNameRef.pathName
}

export const preventBackButton = pathname => {
  window.history.pushState(null, null, pathname)
  return (window.onpopstate = function () {
    window.history.go(1)
  })
}

import React, { useEffect } from 'react'
import { Image } from 'antd'
import appconstant from '../../themes/appconstant'
import Textfield from '../../common'
import { videoDimentions } from '../../constants/constantValues'
import { useDispatch, useSelector } from 'react-redux'
import { postDetailsAction } from '../../redux/actions/postActions.js'
import { constantRoutes } from '../../constants/constantRoutes'
import { url } from '../../redux/axios/apikit'
import BreadCrumb from '../../common/breadCrumb'

const PostDetails = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.postReducer.postDetail)
  const Images = data?.media?.filter(value => value.type === 'image')
  const Videos = data?.media?.filter(value => value.type === 'video')

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(postDetailsAction(localStorage.getItem('postId')))
  }, [])

  return (
    <div>
      <BreadCrumb firstCrumb={{ link: constantRoutes.postmanagement, pathName: appconstant.postManagement }} currentCrumb={appconstant.editPostDetails} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.postDetails}
            </h6>
          </div>
          <br />
          <div className='wrapper_line wrapper-top view_modules view '>
            <div className='form-group'>
              <label>{appconstant.title}</label>
              <Textfield
                className='form-control'
                type='text'
                placeholder={appconstant.title}
                value={data?.title}
                disabled
              />
            </div>
            <div>
              <div className='form-group'>
                <label>{appconstant.description}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  placeholder={appconstant.description}
                  value={data?.description}
                  disabled
                />
              </div>
            </div>
            {Images?.length ? (
              <div className='form-images'>
                <label>{appconstant.image}</label>
                <div className='inline'>
                  {Images?.map(value => {
                    return value.type === 'image' ? (
                      <div className='cross'>
                        <Image src={url + value?.path} />
                      </div>
                    ) : null
                  })}
                </div>
              </div>
            ) : null}
            {Videos?.length ? (
              <div className='form-videos'>
                <label>{appconstant.video}</label>
                <div className='video-clips'>
                  {Videos?.map(value => {
                    return value.type === 'video' ? (
                      <div className='cross'>
                        <video
                          src={url + value?.path}
                          {...videoDimentions}
                          controls
                        />
                      </div>
                    ) : null
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PostDetails

import ApiConstants from "../../themes/apiConstants"
import ValidationConstants from "../../themes/validationConstants"
import { put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { dashboardApi } from "../axios/axiosApi"
import { sessionOut } from "./authSaga"



function* dashboardSaga(action) {
    try {
        let response = yield call(dashboardApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_DASHBOARD_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userview-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

export default function* rootDashboardSaga() {
    yield takeLatest(ApiConstants.API_DASHBOARD_LOAD, dashboardSaga)
}

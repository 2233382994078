import appconstant from "../themes/appconstant"

export const videoDimentions = {
  width: '170px',
  height: '200px'
}

export const time_dateFormat = {
  dateFormat: 'MM-DD-YYYY',
  timeDateFormat: 'MM-DD-YYYY, hh:mm A',
  timeFormat: 'hh:mm A',
  reFormatDate: 'YYYY-MM-DD',
  reFormatTime: 'THH:mm:ssZ',
  reFormat: 'YYYY-MM-DDThh:mm:ss',
  Duration:"HH:mm",
  Month:"YYYY:mm"
}

export const extentions = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png'
}

export const videoExtension = {
  mp4: 'video/mp4'
}

export const constValues = {
  passMinLength: 8,
  imageSize: 10485760,
  videoDurationLimit: 120
}

export const sortValue = (prop) => prop === appconstant.descend ? -1 : prop === appconstant.ascend ? 1 : ''
export const constantRoutes = {
  dashboard: '/dashboard',
  login: '/login',
  forgotpassword: '/forgotpassword',
  resetPassword: '/resetPassword',
  resetPasswordApp: '/resetPasswordApp',
  changepassword: '/changepassword',
  edituserdetails: '/edituserdetails',
  userdetails: '/userdetails',
  usermanagement: '/usermanagement',
  addEvent: '/addEvent',
  editEventDetails: '/editEventDetails',
  eventparticipants: '/eventparticipants',
  eventmanagement: '/eventmanagement',
  eventdetails: '/eventdetails',
  postmanagement: '/postmanagement',
  postdetails: '/postdetails',
  editPostDetails: '/editPostDetails',
  empty: '/',
  other: '/*',
  eula: '/end-user-license-agreement',
  astric: "*",
  addMember:"/addMember"
}

import React, { useEffect } from "react";
import { Image } from "antd";
import images from "../../themes/appImage";
import { useDispatch, useSelector } from "react-redux";
import appconstant from "../../themes/appconstant";
import Textfield from "../../common";
import { viewAction } from "../../redux/actions/userActions.js";
import ValidationConstants from "../../themes/validationConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isInternetConnected } from "../../helper/networkUtils";
import moment from "moment";
import { time_dateFormat } from "../../constants/constantValues";
import MultiSelect from "../../common/multiSelect";
import { url } from "../../redux/axios/apikit";
import { constantRoutes } from "../../constants/constantRoutes";
import { capitalizeFirstLetter } from "../../utils/validator";
import BreadCrumb from "../../common/breadCrumb";

const UserDetails = () => {
  const dispatch = useDispatch();
  let data = useSelector((state) => state.userManagementReducer?.viewUser);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isInternetConnected()) {
      dispatch(viewAction(JSON.parse(localStorage.getItem("userId"))));
    } else {
      toast(ValidationConstants.internetCheck, { id: "viewuser-index" });
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        firstCrumb={{
          link: constantRoutes.usermanagement,
          pathName: appconstant.UserManagement,
        }}
        currentCrumb={appconstant.userDetails}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.userDetails}
            </h6>
          </div>
          <div className="profile-image">
            <Image
              src={data?.profileImage ? url + data?.profileImage : images.edit}
            />
          </div>
          <div className="wrapper_line view_modules view ">
            <div className="form-group">
              <label>{appconstant.userName}</label>
              <Textfield
                className="form-control"
                type="text"
                placeholder={appconstant.userName}
                value={data?.name || appconstant.NA}
                disabled
              />
            </div>
            <div>
              <div className="form-group">
                <label>{appconstant.EmailAddress}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.EmailAddress}
                  value={data?.email || appconstant.NA}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.dateOfJoining}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.dateOfJoining}
                  value={
                    moment(data?.createdAt).format(
                      time_dateFormat.dateFormat
                    ) || appconstant.NA
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.dateOfBirth}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.dateOfBirth}
                  value={
                    data?.dob
                      ? moment(data?.dob).format(time_dateFormat.dateFormat)
                      : appconstant.NA
                  }
                  disabled
                />
              </div>
             
              <div className="form-group">
                <label>{appconstant.interest}</label>
                <MultiSelect
                  className="multiSelect-view"
                  value={data?.intrest?.map((value) =>
                    capitalizeFirstLetter(value?.name)
                  )}
                  placeholder={appconstant.NA}
                  disabled={true}
                  showArrow={false}
                />
              </div>
              <div className="form-group">
                <label>{appconstant.gender}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.gender}
                  value={capitalizeFirstLetter(data?.gender) || appconstant.NA}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.noOfPosts}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.noOfPosts}
                  value={data?.posts}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.noOfFriend}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.noOfFriend}
                  value={data?.friends}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.member}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.member}
                  value={appconstant.packages[0] == data?.membership?.name ? "Frendii Social"
                    : appconstant.packages[1] == data?.membership?.name ? "Frendii Social Plus"
                      : appconstant.packages[2] == data?.membership?.name ? "Frendii Wellness"
                        : appconstant.NA
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.city}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.city}
                  value={data?.city ? data?.city?.name : appconstant.NA}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>{appconstant.state}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.state}
                  value={data?.state ? data?.state?.name : appconstant.NA}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetails;

import React from 'react'
import { DatePicker, Space } from 'antd'

const datePicker = props => {
  const {
    name,
    className,
    onChange,
    inputReadOnly,
    format,
    placeholder,
    error,
    defaultValue,
    disabledDate,
    value
  } = props

  return (
    <Space className='input_fields' direction='vertical'>
      <DatePicker
        className={className}
        name={name}
        placeholder={placeholder}
        inputReadOnly={inputReadOnly}
        onChange={onChange}
        format={format}
        defaultValue={defaultValue}
        disabledDate={disabledDate}
        value={value}
      />
      <span className='login-error'>{error ? error : ''}</span>
    </Space>
  )
}
export default datePicker

const appconstant = {
  price:"Price",
  deleteP:"Delete Permanently",
  cancel:"cancel",
  expirationDate:"Expiration Date",
  priceUSD:"Price (USD)",
  promoDesc:"Promo Description",
  paymentMethod:"Payment Method",
  paidStatus:"Paid Status",
  type:"Type",
  duration:"Duration",
  reasonBeingAdd:"Reason being Added",
  isAmbassador:"isAmbassador",
  ambassador:"Ambassador",
  eventFor:"Who Can See",
  changeRole:"Change Role",
  dashboard: "Dashboard",
  changepassword: "Change Password",
  eventEndDate: "Event End Date",
  TimeDuration: "Time Duration",
  going: "Going",
  addEvent: "Add Event",
  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Dashboard",
  image: "Images",
  video: "Videos",
  EmailAddress: "Email Address",
  eventManagement: "Event Management",
  editEventDetails: "Edit Event Details",
  postDetails: "Post Details",
  timeZone: "Time Zone",
  eventName: "Event Name",
  EventParticipants: "Event Participants",
  eventStartTime: "Event Start Time",
  UserManagement: "User Management",
  postManagement: "Post Management",
  editPostDetails: "Edit Post Details",
  view: "View",
  edit: "Edit",
  dateOfJoining: "Date of Joining",
  dateOfBirth: "Date of Birth",
  interest: "Interests",
  noOfFriend: "No. of Friends",
  noOfPosts: "No. of Posts",
  member: "Membership",
  block: "Block",
  unBlock: "Unblock",
  update: "Update",
  export: "Export",
  add: "Add",
  addMember: "Add Member",
  Password: "Password",
  ConfrmPassword: "Confirm Password",
  email: "Email",
  contactNo: "Contact No",
  gender: "Gender",
  age: "Age",
  name: "Name",
  organizerDetails: "Organizer Details",
  eventOrganizermanagement: "Event Organizer management",
  delete: "Delete ",
  purposeManagement: "Purpose Management",
  eventDetails: "Event Details",
  eventDate: "Event Date",
  eventStartDate: "Event Start Date",
  eventPlace: "Event Place",
  editEventDetail: "Edit Event Detail",
  editUserDetails: "Edit User Details",
  editDetails: "Edit Details",
  editPurposeDetail: "Edit Purpose Detail",
  purposeOfTheEvent: "Purpose Of The Event",
  userDetails: "User Details",
  buttonedit: "Edit",
  buttonAdd: "Add",
  orderDateTime: "Order Date/Time",
  userName: "User Name",
  post: "Post",
  event: "event",
  user: "user",
  phoneNumber: "Phone Number",
  messages: "Messages",
  john: "john",
  emailname: "john@yopmail.com",
  number: "8898765578",
  address: "Address",
  mohali: "Mohali",
  title: "Title",
  description: "Description",
  addImage: "Add image.",
  addVideo: "Add video.",
  lastname: "Last Name",
  firstname: "First Name",
  designation: "Designation",
  details: "Details",
  addclient: "Add Client",
  Home: "Home",
  blog: "Blog",
  buttonupate: "Update",
  search: "Search",
  state: "State",
  city: "City",
  NA: "N/A",
  eventDescription: "Event Short Description",
  eventAddress: "Event location/Address",
  logout: "Logout",
  change: "change",
  upload: "upload",
  ascend: "ascend",
  descend: "descend",
  resetPassword: "Reset Password",
  Rest: "Reset",
  Yes: "Yes",
  No: "No",
  Send: "Send",
  ForgotPassword: "Forgot Password",
  packages: ["com.frendii.social", "com.frendii.socialplus", "com.frendii.wellness"]
};
export default appconstant;

import './App.css'
import React, { useEffect } from 'react'
import '../src/css/style.css'
import '../src/css/responsive.css'
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/antd.css'
import { setNavigate } from './Route/navigationServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import RoutesNew from './Route/Routes'

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setNavigate(navigate, location)
  }, [])

  return (
    <>
      <RoutesNew />
      <ToastContainer limit={1} autoClose={1600} />
    </>
  )
}

export default App

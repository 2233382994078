import React, { useEffect } from 'react'
import images from '../../themes/appImage'
import { useDispatch, useSelector } from 'react-redux'
import { isInternetConnected } from '../../helper/networkUtils'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ValidationConstants from '../../themes/validationConstants'
import { dashboardAction } from '../../redux/actions/dashboardActions.js'
import BreadCrumb from '../../common/breadCrumb'
import { Collapse } from 'antd'
import { DataManager } from '../../helper/dataManager'
const { Panel } = Collapse


const Dashboard = () => {
  let type = DataManager.getUserType()
  const dispatch = useDispatch()
  const dashData = useSelector(
    state => state.dashboardReducer.userData
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isInternetConnected()) {
      dispatch(dashboardAction())
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 'dashboard' })
    }

    back()
    window.addEventListener('popstate', back)
    return () => {
      window.removeEventListener('popstate', back)
    }
  }, [])

  const back = () => {
    window.history.pushState(null, document.title, window.location.href)
  }

  return (
    <div>
      <BreadCrumb />
      {type == "admin" &&(
        <>
      <Collapse className='data_n' expandIconPosition='end' defaultActiveKey={"0"}>
        <Panel header="Total Revenue/ Active Subscriptions">
          <div className='main-div-2 dashboard '>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.activeSubscriptions?.totalRevenue}$</h1>
                </div>
                <div>
                  <img alt='' src={images.dollar} className='dashboard-images dollar' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Total revenue: {dashData?.activeSubscriptions?.totalRevenue}$
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.activeSubscriptions?.social}</h1>
                </div>
                <div>
                  <img alt='' src={images.monthly_dollar} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Current active frendii social users: {dashData?.activeSubscriptions?.social}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.activeSubscriptions?.wellness}</h1>
                </div>
                <div>
                  <img alt='' src={images.Annually} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Current active frendii wellness users: {dashData?.activeSubscriptions?.wellness}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.activeSubscriptions?.socialPlus}</h1>
                </div>
                <div>
                  <img alt='' src={images.Annually} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Current active frendii social plus users: {dashData?.activeSubscriptions?.socialPlus}
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse className='data_n' expandIconPosition='end' defaultActiveKey={"0"}>
        <Panel header="Total Number Of Subscriptions">
          <div className='main-div-2 dashboard '>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.totalSubscriptions?.today}</h1>
                </div>
                <div>
                  <img alt='' src={images.today} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Today’s total subscriptions: {dashData?.totalSubscriptions?.today}
                </p>
              </div>
            </div>

            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.totalSubscriptions?.lastWeek}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventLastWeek} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Total subscriptions in last week: {dashData?.totalSubscriptions?.lastWeek}
                </p>
              </div>
            </div>

            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.totalSubscriptions?.lastMonth}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventMonth} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Total subscriptions in last month: {dashData?.totalSubscriptions?.lastMonth}
                </p>
              </div>
            </div>

            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.totalSubscriptions?.last6Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.event6months} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Total subscriptions in last 6 months : {dashData?.totalSubscriptions?.last6Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.totalSubscriptions?.lastYear}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventYear} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  Total subscriptions in last 1 year: {dashData?.totalSubscriptions?.lastYear}
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse className='data_n' expandIconPosition='end' defaultActiveKey={"0"}>
        <Panel header="Total Users Registered On Application" >
          <div className='main-div-2 dashboard '>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersRegistered?.lastWeek}</h1>
                </div>
                <div>
                  <img alt='' src={images.userLastWeek} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users registered in last week:{' '}
                  {dashData?.usersRegistered?.lastWeek}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersRegistered?.lastMonth}</h1>
                </div>
                <div>
                  <img alt='' src={images.userMonth} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users registered in last month :{' '}
                  {dashData?.usersRegistered?.lastMonth}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersRegistered?.last3Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.userQuater} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users registered in last quarter:{' '}
                  {dashData?.usersRegistered?.last3Month}
                </p>
              </div>
            </div>

            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersRegistered?.last6Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.user6months} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users registered in last 6 months :{' '}
                  {dashData?.usersRegistered?.last6Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersRegistered?.lastYear}</h1>
                </div>
                <div>
                  <img alt='' src={images.userYear} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users registered in last 1 year :{' '}
                  {dashData?.usersRegistered?.lastYear}
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse className='data_n' expandIconPosition='end' defaultActiveKey={"0"}>
        <Panel header='Total Users Who Left The Application'>
          <div className='main-div-2 dashboard '>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersLeft?.lastWeek}</h1>
                </div>
                <div>
                  <img alt='' src={images.userLastWeek} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users left in last week: {dashData?.usersLeft?.lastWeek}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersLeft?.lastMonth}</h1>
                </div>
                <div>
                  <img alt='' src={images.userMonth} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users left in last month : {dashData?.usersLeft?.lastMonth}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersLeft?.last3Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.userQuater} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users left in last quarter:{' '}
                  {dashData?.usersLeft?.last3Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersLeft?.last6Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.user6months} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users left in last 6 months :{' '}
                  {dashData?.usersLeft?.last6Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.usersLeft?.lastYear}</h1>
                </div>
                <div>
                  <img alt='' src={images.userYear} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of users left in last 1 year : {dashData?.usersLeft?.lastYear}
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      </>
      )
}
      <Collapse className='data_n' expandIconPosition='end' defaultActiveKey={"0"}>
        <Panel header='Total Number Of Events'>
          <div className='main-div-2 dashboard '>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.event?.lastWeek}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventLastWeek} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of events registered in last week: {dashData?.event?.lastWeek}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.event?.lastMonth}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventMonth} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of events registered in last month :{' '}
                  {dashData?.event?.lastMonth}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.event?.last3Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventQuater} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of events registered in last quarter:{' '}
                  {dashData?.event?.last3Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.event?.last6Month}</h1>
                </div>
                <div>
                  <img alt='' src={images.event6months} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of events registered in last 6 months :{' '}
                  {dashData?.event?.last6Month}
                </p>
              </div>
            </div>
            <div className='datacenter user-valid-text'>
              <div className='image-fle'>
                <div className='total-num'>
                  <h1>{dashData?.event?.lastYear}</h1>
                </div>
                <div>
                  <img alt='' src={images.eventYear} className='dashboard-images' />
                </div>
              </div>
              <div className='user-text'>
                <p>
                  No. of events registered in last 1 year :{' '}
                  {dashData?.event?.lastYear}
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}
export default Dashboard

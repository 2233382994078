import ApiConstants from '../../themes/apiConstants'

const initialState = {
    onLoad: true,
    userData: null,
    postDetail: [],
    data: null,
    viewUser: null,
    eventData: null,
    eventViewData: null,
    interestData: null
}

function postReducer(state = initialState, action) {
    switch (action.type) {
        case ApiConstants.API_POST_LIST_SUCCESS:
            return { ...state, postList: action.payload }

        case ApiConstants.API_POST_DETAILS_SUCCESS:
            return { ...state, postDetail: action.payload }

        default:
            return state
    }
}

export default postReducer

import React, { useEffect } from 'react'
import images from '../../themes/appImage'
import { Link } from 'react-router-dom'
import Textfield from '../../common'
import ValidationConstants from '../../themes/validationConstants'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { isInternetConnected } from '../../helper/networkUtils'
import * as Yup from 'yup'
import { validateEmailRgx, whitespacesRgx } from '../../utils/validator'
import { ValidationFunction } from '../../themes/validationFunctions'
import appconstant from '../../themes/appconstant'
import { loginAction } from '../../redux/actions/authActions.js'

const Login = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    back()
    window.addEventListener('popstate', back)
    return () => {
      window.removeEventListener('popstate', back)
    }
  }, [])

  const back = () => {
    window.history.pushState(null, document.title, window.location.href)
  }

  const onSubmit = values => {
    if (isInternetConnected()) {
      dispatch(loginAction(values))
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 'login' })
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        validateEmailRgx,
        ValidationFunction.validField(appconstant.EmailAddress.toLocaleLowerCase())
      )
      .required(ValidationFunction.emptyField(appconstant.EmailAddress.toLocaleLowerCase())),
    password: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(appconstant.Password))
      .required(ValidationFunction.emptyField(appconstant.Password.toLocaleLowerCase()))
  })

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit
  })

  return (
    <div className='image-bg'>
      <div className='page-header'>
        <div className='main-div'>
          <div className='login-form'>
            <div>
              <img src={images.login} />
            </div>
            <h3>Admin Login </h3>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className='user'>
                <Textfield
                  onChange={formik.handleChange('email')}
                  value={formik.values.email.trim()}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                  placeholder={appconstant.EmailAddress}
                />
              </div>
              <div className='user'>
                <Textfield
                  onChange={formik.handleChange('password')}
                  value={formik.values.password.trim()}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  type={appconstant.Password.toLocaleLowerCase()}
                  placeholder={appconstant.Password}
                />
              </div>
              <div>
                <button type='submit' className='login-link'>
                  Login
                </button>
              </div>
            </form>
            <div className='forgot-link'>
              <Link to='/forgotpassword'>Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login

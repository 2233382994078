import React, { useState } from 'react'
import { Layout, Spin } from 'antd'
import { Outlet } from 'react-router'
import HeaderAdmin from '../layout/header'
import Sidebar from '../layout/sidebar'
const { Content } = Layout

const MainLayout = props => {
  const [isVisible, setVisible] = useState(false)

  const handlewClick = () => {
    setVisible(!isVisible)
  }

  return (
    <Spin spinning={false} size='large'>
      <Layout>
        <Sidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className={isVisible ? 'new_layout data' : 'new_layout'}>
          <HeaderAdmin handlewClick={handlewClick} />
          <Content style={{ margin: '24px 16px 0' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Spin>

  )
}
export default MainLayout

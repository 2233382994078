import { constantRoutes } from "../../constants/constantRoutes"
import { navigate } from "../../Route/navigationServices"
import ApiConstants from "../../themes/apiConstants"
import ValidationConstants from "../../themes/validationConstants"
import { put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { DeletePostApi, postBlockApi, postDetailsApi, postListApi, postUpdateApi } from "../axios/axiosApi"
import { sessionOut } from "./authSaga"

function* postListSaga(action) {
    try {
        let response = yield call(postListApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_POST_LIST_SUCCESS,
                payload: result?.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* postBlockSaga(action) {
    try {
        let response = yield call(postBlockApi, action.payload?.id)
        let { result, status } = response

        if (status === 1) {
            toast(result?.message, { toastId: '3' })
            yield call(postListSaga, { payload: action.payload.onload })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* postDeleteSaga(action) {
    try {
        let response = yield call(DeletePostApi, action.payload?.id)
        let { result, status } = response

        if (status === 1) {
            toast(result?.message, { toastId: '3' })
            yield call(postListSaga, { payload: action.payload.onload })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* postDetailsSaga(action) {
    try {
        let response = yield call(postDetailsApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            yield put({
                type: ApiConstants.API_POST_DETAILS_SUCCESS,
                payload: result.data
            })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

function* postEditSaga(action) {
    try {
        let response = yield call(postUpdateApi, action.payload)
        let { result, status } = response

        if (status === 1) {
            navigate(constantRoutes.postmanagement)
            toast(result.message, { toastId: 35 })
        } else if (status === 2) {
            yield call(sessionOut, result?.msg)
        } else {
            toast(result?.msg, { toastId: 'userList-passowrd-error' })
        }
    } catch (error) {
        toast(ValidationConstants.internetCheck)
    }
}

export default function* rootPostSaga() {
    yield takeLatest(ApiConstants.API_POST_DETAILS_LOAD, postDetailsSaga)
    yield takeLatest(ApiConstants.API_POST_UPDATE_LOAD, postEditSaga)
    yield takeLatest(ApiConstants.API_DELETE_POST_LOAD, postDeleteSaga)
    yield takeLatest(ApiConstants.API_POST_BLOCK_LOAD, postBlockSaga)
    yield takeLatest(ApiConstants.API_POST_LIST_LOAD, postListSaga)

}
import ApiConstants from '../../themes/apiConstants'

const initialState = {
    onLoad: true,
    userData: null,
    postDetail: [],
    data: null,
    viewUser: null,
    eventData: null,
    eventViewData: null,
    interestData: null,
    addLoader:false
}

function userManagementReducer(state = initialState, action) {
    switch (action.type) {

        ////User List reducer....
        case ApiConstants.API_USERLIST_SUCCESS:
            return { ...state, onLoad: true, userData: action.payload }

        case ApiConstants.API_INTEREST_LIST_SUCCESS:
            return { ...state, interestData: action.payload }
        ///User View.........
        case ApiConstants.API_VIEW_SUCCESS:
            return { ...state, viewUser: action.payload }

            case ApiConstants.API_ADD_LOAD:
                return { ...state,addLoader:true  }
            case ApiConstants.API_ADD_SUCCESS:
                return { ...state,addLoader:false    }
                case ApiConstants.API_ADD_FAIL:
                    return { ...state,addLoader:false}

        case ApiConstants.API_USER_ADDRESS_SUCCESS:
            return { ...state, location: action.payload }

        ////Subscription...........
        case ApiConstants.API_SUBSCRIPTION_SUCCESS:
            return { ...state, subcribed: action.payload }

        case ApiConstants.API_USERLIST_LOAD:
            return { ...state, roleUpdate: false }

        case ApiConstants.CHANGE_USER_ROLE_SUCCESS:
            return { ...state, roleUpdate: true }

            case ApiConstants.API_DELETE_USER_LOAD:
                return { ...state,onLoad:true  }
            case ApiConstants.API_DELETE_USER_SUCCESS:
                return { ...state,onLoad:false    }
                case ApiConstants.API_DELETE_USER_FAIL:
                    return { ...state,onLoad:false}

        default:
            return state
    }
}

export default userManagementReducer
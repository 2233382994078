import ApiConstants from "../../themes/apiConstants"

export const timezoneAction = payload => {
    return {
        type: ApiConstants.API_TIMEZONE_LOAD,
        payload
    }
}

export const createEventAction = payload => {
    return {
        type: ApiConstants.API_CREATEEVENT_LOAD,
        payload
    }
}

export const viewEventAction = payload => {
    return { type: ApiConstants.API_EVENTVIEW_LOAD, payload }
}

export const editEventAction = payload => {
    return { type: ApiConstants.API_EVENTEDIT_LOAD, payload }
}

export const eventParticipantsAction = payload => {
    return {
        type: ApiConstants.API_PARTICIPANTS_LOAD,
        payload
    }
}

export const deleteEventAction = payload => {
    return { type: ApiConstants.API_EVENTDELETE_LOAD, payload }
}

export const eventManagementAction = payload => {
    return { type: ApiConstants.API_EVENTLIST_LOAD, payload }
}
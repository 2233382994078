import ApiConstants from "../../themes/apiConstants"


export const loginAction = payload => {
  return {
    type: ApiConstants.API_LOGIN_LOAD,
    payload
  }
}

export const logoutAction = userId => {
  return {
    type: ApiConstants.API_LOGOUT_LOAD,
    userId
  }
}

export const forgotPasswordAction = payload => {
  return {
    type: ApiConstants.API_FORGOT_PASSWORD_LOAD,
    payload
  }
}

export const changePassAction = payload => {
  return {
    type: ApiConstants.API_CHANGE_PASSWORD_LOAD,
    payload
  }
}

export const resetAction = payload => {
  return {
    type: ApiConstants.API_RESET_LOAD,
    payload
  }
}

export const resetPasswordLinkAction = payload => {
  return {
    type: ApiConstants.API_RESETLINK_LOAD,
    payload
  }
}

export const pageDetails = payload => {
  return {
    type: ApiConstants.PAGEDETIALS,
    payload
  }
}


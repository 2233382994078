// import React, { useState, useEffect, useRef } from 'react'
// import images from '../../themes/appImage'
// import { Image } from 'antd'
// import appconstant from '../../themes/appconstant'
// import {
//     editAction,
//     interestListAction,
//     userAddressAction,
//     viewAction
// } from '../../redux/actions/userActions.js'
// import ValidationConstants from '../../themes/validationConstants'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import { isInternetConnected } from '../../helper/networkUtils'
// import moment from 'moment'
// import { time_dateFormat, extentions } from '../../constants/constantValues'
// import MultiSelect from '../../common/multiSelect'
// import { useDispatch, useSelector } from 'react-redux'
// import Textfield from '../../common'
// import { url } from '../../redux/axios/apikit'
// import { constantRoutes } from '../../constants/constantRoutes'
// import {
//     ValidationFunction
// } from '../../themes/validationFunctions'
// import { DropDownSelect } from '../../common/DropDownSelect'
// import { capitalizeFirstLetter } from '../../utils/validator'
// import BreadCrumb from '../../common/breadCrumb'
// import Password from 'antd/lib/input/Password.js'
import React, { useState, useEffect, useRef } from "react";
import images from "../../themes/appImage";
import { Image, Select, Spin } from "antd";
import appconstant from "../../themes/appconstant";
import Textfield from "../../common";
import TimePicker from "../../common/TimePicket";
import DatePicker from "../../common/DatePicker";
import { constantRoutes } from "../../constants/constantRoutes";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import {
    constValues,
    time_dateFormat,
    extentions,
} from "../../constants/constantValues";
import moment from "moment-timezone";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ValidationConstants from "../../themes/validationConstants";
import { beginningSpace, capitalizeFirstLetter, validateEmailRgx, whitespacesRgx } from "../../utils/validator";
import { ValidationFunction } from "../../themes/validationFunctions";
import { DropDownSelect } from "../../common/DropDownSelect";
import {
    createEventAction,
    timezoneAction,
} from "../../redux/actions/eventActions.js";
import BreadCrumb from "../../common/breadCrumb";
import { addAction, userAddressAction } from "../../redux/actions/userActions";
import TextAreafield from "../../common/TextAreaField";
import { Option } from "antd/lib/mentions";
import GooglePlacesAutoComplete from "../../common/googlePlacesAutoComplete";
import Password from "antd/lib/input/Password.js";
var isSpin = false;

const AddMember = () => {
    const refs = useRef();
    const [image, setImage] = useState(null);
    const [isSpin, setIsSpin] = useState(false);
    const [eventFor, setEventFor] = useState('paid')
    const [reasonAdd, setreasonAdd] = useState()

    const [isVisible, setIsVisible] = useState(false);
    const [latlng, setLatlng] = useState({
        lat: 0,
        lng: 0
    });
    const dispatch = useDispatch();
    const locations = useSelector(
        (state) => state.userManagementReducer?.location
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(timezoneAction());
        dispatch(userAddressAction());
    }, []);

    const imageSet = (e) => {
        if (
            e.target.files[0]?.type === extentions.jpeg ||
            e.target.files[0]?.type === extentions.png ||
            e.target.files[0]?.type === extentions.jpg
        ) {
            if (e.target.files[0]?.size < constValues.imageSize) {
                setImage(e.target.files[0]);
            } else {
                toast(ValidationConstants.fileSize);
            }
        } else {
            toast(ValidationConstants.fileExtention);
        }
    };

    const onSubmit = (e) => {

        // const body = new FormData();
        setIsSpin(true);
        // let startDate = moment(e?.startDate).format("YYYY-MM-DD");
        // let startTime = moment(e.startTime).format("HH:mm:ss");

        // let hoursDuration = parseInt(e.duration.split(":")[0]);
        // let minuteDuration = e.duration.split(":")[1];
        // let START_TIME = moment(`${startDate}T${startTime}`)
        //     .utc()
        //     .format("YYYY-MM-DDTHH:mm:ssZ");
        // let END_TIME = null;

        // if (hoursDuration > 0)
        //     END_TIME = moment(`${startDate}T${startTime}`)
        //         .utc()
        //         .add(hoursDuration, "hours")
        //         .format("YYYY-MM-DDTHH:mm:ssZ");
        // if (minuteDuration > 0)
        //     END_TIME = moment(`${startDate}T${startTime}`)
        //         .utc()
        //         .add(minuteDuration, "minutes")
        //         .format("YYYY-MM-DDTHH:mm:ssZ");

        // body.append(
        //     "startDate",
        //     moment(`${startDate}T${startTime}`).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        // );
        // body.append("endDate", END_TIME);
        // body.append("startTime", START_TIME);
        // body.append("name", e?.name);
        // body.append("description", e?.description);
        // body.append("image", image);
        // body.append("address", e?.address);
        // body.append("latitude", latlng.lat);
        // body.append("longitude", latlng.lng);
        // body.append("visibleTo", eventFor)
        // body.append("duration", e?.duration);
        // dispatch(createEventAction(body));

        // if (image) {
        console.log(e, "1234567890");
        let payload = {
            "name": e.name,
            "email": e.email,
            "password": e.password,
            "deviceToken": "44",
            "deviceType": "ios",
            "isAmbassador": false,
            "isAdmin": false,
            "reason": e.reason,
            "purchaseName": `com.frendii.${e.purchaseName}`,
            "duration": e.duration,
            "validUpTo": moment().add(1, e.duration).toISOString(),
            "paidStatus": e.paidStatus,
            "description": e.description,
            "price": e.price,
            paymentMethod: e.paymentMethod
            // "purchaseName":"sjdgh",
            // "validUpTo":"2024-12-30T19:11:31.283+00:00",
            // duration:"month"
        }
        dispatch(addAction(payload));
        // } else {
        //     setIsSpin(false);
        //     toast(ValidationConstants.profileRequired, { toastId: 22 });
        // }
        setTimeout(() => {
            setIsSpin(false);
        }, 1500);
    };

    const validationSchema = Yup.object().shape({

        name: Yup.string()
            .required(ValidationConstants.userNameRequired)
            .matches(
                beginningSpace,
                ValidationFunction.validField(capitalizeFirstLetter(appconstant.userName).toLocaleLowerCase())
            )
            .min(3, ValidationFunction.minlength(capitalizeFirstLetter(appconstant.userName.toLocaleLowerCase()))),
        email: Yup.string()
            .matches(
                validateEmailRgx,
                ValidationFunction.validField(appconstant.EmailAddress.toLocaleLowerCase())
            )
            .required(ValidationFunction.emptyField(appconstant.EmailAddress.toLocaleLowerCase())),
        password: Yup.string()
            .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.Password)))
            .min(constValues.passMinLength, ValidationFunction.minlength(capitalizeFirstLetter(appconstant.Password), constValues.passMinLength))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, ValidationFunction.password(capitalizeFirstLetter(appconstant.Password)))
            .required(ValidationFunction.emptyField(appconstant.Password.toLocaleLowerCase())),
        confirmPassword: Yup.string()
            .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.ConfrmPassword)))
            .required(ValidationFunction.emptyField(appconstant.ConfrmPassword.toLocaleLowerCase()))
            .oneOf(
                [Yup.ref(appconstant.Password.toLocaleLowerCase()), null],
                ValidationConstants.invalidConfirm_Password
            ),
        reason: Yup.string().nullable().required("Please select the reason."),
        // duration: Yup.string().nullable().required("Please select the duration."),
        purchaseName: Yup.string().nullable().required("Please select the type."),
        paymentMethod: Yup.string().nullable().required("Please select the payment method."),
        paidStatus: Yup.string().nullable().required("Please select the paid status."),
        // price:Yup
        // .number()
        // .typeError(`Price must be Valid.`)
        // .nullable()
        // .required(ValidationConstants.price)
        // .min(1,"Price must be greater than or equal to 1.")

    });
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            reason: null,
            purchaseName: null,
            duration: null,
            validUpTo: "",
            price: null,
            description: "",
            paymentMethod: null,
            paidStatus: null,
        },
        validationSchema,
        onSubmit,
        // validate,
    });

    const state = locations?.filter(
        (value) => value?._id === formik.values?.state
    )?.[0]?.name;
    const cities = locations?.filter((value) => {
        if (value?._id === formik.values?.state) {
            return value?.cities;
        }
    })?.[0]?.cities;

    return (
        <div>
            <BreadCrumb firstCrumb={{ link: constantRoutes.usermanagement, pathName: appconstant.UserManagement }} currentCrumb={appconstant.addMember} />
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3">
                            {appconstant.addMember}
                        </h6>
                    </div>
                    {/* <div className="profile-image">
                        <Image
                            preview={false}
                            type="image"
                            src={image ? URL.createObjectURL(image) : images.edit}
                            className={image && "upload_image"}
                        />
                        <div className="datacenter-icon">
                            <input
                                type="image"
                                src={images.pluss}
                                title={ValidationFunction.clickToImg(
                                    image
                                        ? appconstant.change
                                        : appconstant.add.toLocaleLowerCase()
                                )}
                                onClick={() => refs.current.click()}
                            />
                            {console.log(formik.values.startTime)}
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={refs}
                                onChange={imageSet}
                                accept={Object.values(extentions)}
                                onClick={(e) => (e.target.value = null)}
                            />
                        </div>
                    </div> */}
                    <div className="wrapper_line view_modules view ">
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div>
                                <div className="form-group">
                                    <label>{appconstant.userName}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.userName}
                                        onChange={(text) =>
                                            formik.handleChange("name")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.name}
                                        error={formik.errors.name}
                                        maxLength={50}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.EmailAddress}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.EmailAddress}
                                        onChange={(text) =>
                                            formik.handleChange("email")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.email}
                                        error={formik.errors.email}

                                    />
                                </div>

                                <div className="form-group">
                                    <label>{appconstant.Password}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.Password}
                                        onChange={(text) =>
                                            formik.handleChange("password")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.password}
                                        error={formik.errors.password}

                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.ConfrmPassword}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.ConfrmPassword}
                                        onChange={(text) =>
                                            formik.handleChange("confirmPassword")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.confirmPassword}
                                        error={formik.errors.confirmPassword}

                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.reasonBeingAdd}</label>
                                    <div className='input_fields'>
                                        <Select
                                            style={{
                                                width: 120,
                                                color: '#000000'
                                            }}
                                            placeholder={"Select"}
                                            name={"reason"}
                                            value={formik.values.reason}
                                            onChange={(e) => formik.handleChange("reason")(e)}
                                        >
                                            <Option title="" value="promotion">
                                                Promotion
                                            </Option>
                                            <Option title="" value="gift">
                                                Gift
                                            </Option>
                                            <Option title="" value="neededSupport">
                                                Needed Support
                                            </Option>
                                            <Option title="" value="testing">
                                                Testing
                                            </Option>
                                        </Select>
                                        <span className='login-error'>
                                            {formik.errors.reason}
                                        </span>
                                    </div>
                                </div>

                                <h1>Membership Details</h1>
                                <div className="form-group">
                                    <label>{appconstant.type}</label>
                                    <div className="input_fields">
                                        <Select
                                            style={{
                                                width: 120,
                                                color: '#000000'
                                            }}
                                            placeholder={"Select"}
                                            name={"purchaseName"}
                                            value={formik.values.purchaseName}
                                            onChange={(e) => formik.handleChange("purchaseName")(e)}
                                            onBlur={()=>{
                                             var   date = moment(Date.now()).add(1, 'month').format("DD-MM-YYYY")
                                             var price = formik.values.purchaseName == "wellness" ? 109.99 :149.99
                                                if(formik.values.purchaseName == "wellness" || formik.values.purchaseName == "socialplus"){
                                                    date = moment(Date.now()).add(1, 'month').format("DD-MM-YYYY")
                                                    price = formik.values.purchaseName == "wellness" ? "109.99" :"149.99"
                                                    formik.handleChange("duration")("year")
                                                    formik.handleChange("validUpTo")(date)
                                                    formik.handleChange("price")(price)
                                                   
                                                }
                                                else{
                                                    date = moment(Date.now()).add(1, 'year').format("DD-MM-YYYY")
                                                    formik.handleChange("duration")("month")
                                                    formik.handleChange("validUpTo")(date)
                                                    formik.handleChange("price")("15.99")
                                                }
                                            }}
                                        >
                                            <Option title="" value="wellness">
                                                WELLNESS
                                            </Option>
                                            <Option title="" value="social">
                                                Social
                                            </Option>
                                            <Option title="" value="socialplus">
                                                Social Plus
                                            </Option>
                                        </Select>
                                        <span className='login-error'>
                                            {formik.errors.purchaseName
                                            }
                                        </span>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label>{appconstant.duration}</label>
                                    <div className="input_fields">
                                        <Select
                                            style={{
                                                width: 120,
                                                color: '#000000'
                                            }}
                                            placeholder={"Select"}
                                            name={"duration"}
                                            disabled={true}
                                            value={formik.values.duration}
                                            onChange={(e) => {
                                                formik.handleChange("duration")(e)
                                            }}
                                            onBlur={() => {
                                                var date
                                                if (formik.values.duration == "month") {
                                                    date = moment(Date.now()).add(1, 'month').format("DD-MM-YYYY")
                                                } else {
                                                    date = moment(Date.now()).add(1, 'year').format("DD-MM-YYYY")
                                                }
                                                formik.handleChange("validUpTo")(date)
                                            }}
                                        >
                                            <Option title="" value="month">
                                                Monthly
                                            </Option>
                                            <Option title="" value="year">
                                                Yearly
                                            </Option>
                                        </Select>
                                        <span className='login-error'>
                                            {formik.errors.duration}
                                        </span>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label>{appconstant.expirationDate}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.expirationDate}
                                        onChange={(text) =>
                                            formik.handleChange("validUpTo")(text.target.value.trimLeft())
                                        }
                                        disabled={true}
                                        value={formik.values.validUpTo}
                                        error={formik.errors.validUpTo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.priceUSD}</label>
                                    <Textfield
                                        className="form-control"
                                        type="number"
                                        min={0}
                                        disabled={true}
                                        placeholder={appconstant.priceUSD}
                                        onChange={(text) =>
                                            formik.handleChange("price")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.price}
                                        error={formik.errors.price}

                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.promoDesc}</label>
                                    <Textfield
                                        className="form-control"
                                        type="text"
                                        placeholder={appconstant.promoDesc}
                                        onChange={(text) =>
                                            formik.handleChange("description")(text.target.value.trimLeft())
                                        }
                                        value={formik.values.description}
                                        error={formik.errors.description}

                                    />
                                </div>
                                <div className="form-group">
                                    <label>{appconstant.paymentMethod}</label>
                                    <div className="input_fields">
                                        <Select
                                            style={{
                                                width: 120,
                                                color: '#000000'
                                            }}
                                            placeholder={"Select"}
                                            name={"paymentMethod"}
                                            value={formik.values.paymentMethod}
                                            onChange={(e) => formik.handleChange("paymentMethod")(e)}
                                        >
                                            <Option title="" value="cash">
                                                Cash
                                            </Option>
                                            <Option title="" value="check">
                                                Check
                                            </Option>
                                            <Option title="" value="creditCard">
                                                Credit Card
                                            </Option>
                                            <Option title="" value="payPal">
                                                PayPal
                                            </Option>
                                        </Select>
                                        <span className='login-error'>
                                            {formik.errors.paymentMethod}
                                        </span>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label>{appconstant.paidStatus}</label>
                                    <div className="input_fields">
                                        <Select
                                            style={{
                                                width: 120,
                                                color: '#000000'
                                            }}
                                            placeholder={"Select"}
                                            name={"paidStatus"}
                                            value={formik.values.paidStatus}
                                            onChange={(e) => formik.handleChange("paidStatus")(e)}
                                        >
                                            <Option title="" value="paid">
                                                Paid
                                            </Option>
                                            <Option title="" value="notPaid">
                                                Not Paid
                                            </Option>
                                            <Option title="" value="complementary">
                                                Complementary
                                            </Option>
                                        </Select>
                                        <span className='login-error'>
                                            {formik.errors.paidStatus}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="up-btn">
                                <button disabled={isSpin} type="submit" className="button-list">
                                    {isSpin ? <Spin spinning></Spin> : appconstant.add}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AddMember

//REGEX CODES.....
export const beginningSpace = /^[^\s]+(\s+[^\s]+)*/          //Detects space onlt at beginning.
export const whitespacesRgx = /^\S*$/                        //Detects any space.
export const validateEmailRgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PasswordRegex =  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$/

//VAlIDATORS.....
export const validateEmail = email => {
  let re = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/
  return re.test(email)
}

export const capitalizeFirstLetter = string => {
  const newString = string
    ?.toLowerCase()
    ?.replace(/^./, string[0]?.toUpperCase())
  return newString
}

export const validateEmailLength = (Yup, ValidationConstants) => {
  let vel = Yup.string()
    .matches(
      /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,
      ValidationConstants.valEmail
    )
    .email(ValidationConstants.valEmail)
    .max(320, 'Maximum 320 characters are allowed.')
    .required(ValidationConstants.reqEmail)
  return vel
}

export const validateWhitespaces = email => {
  const ch = /\s/.test(email)
  return ch
}

//PASSWORD VALIDATION CHAECK FUNCTION
export const validatePassword = password => {
  var pw = /^\S*$/
  return pw.test(password)
}

export const validatePhone = phoneNumber => {
  const regPhone = /^(?!0+$)\d{8,}$/
  return regPhone.test(phoneNumber)
}

export const validateUsername = username => {
  const regUsername = /[a-zA-Z0-9._]$/
  return regUsername.test(username)
}

export const validateName = name => {
  const regName = /^[a-zA-Z0-9 ]+$/
  return regName.test(name)
}

export const phoneNumberValidate = () => {
  let ph = / ^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  return ph.test()
}

export const updateProfileCheck = (name, email, ValidationConstants) => {
  let showErrorMessage = ''

  if (name == '' || name.trim().length == 0) {
    showErrorMessage = ValidationConstants.emptyFirstName
  } else if (name.length < 3) {
    showErrorMessage = ValidationConstants.shortFirstName
  } else if (!validateUsername(name)) {
    showErrorMessage = ValidationConstants.userNamevalidation
  } else if (email == '' || email.trim().length == 0) {
    showErrorMessage = ValidationConstants.emptyEmail
  } else if (!validateEmail(email.trim())) {
    showErrorMessage = ValidationConstants.invalidEmail
  }

  return showErrorMessage
}

export const loginCheck = (field, ValidationConstants) => {
  const { email, password } = field
  let emailErrorMessage = ''
  let passwordErrorMessage = ''

  if (!validateEmail(email) || email.includes(' ')) {
    emailErrorMessage = ValidationConstants.invalidEmail
  } else if (email == '' || email?.trim()?.length == 0) {
    emailErrorMessage = ValidationConstants.emptyEmail
  }

  if (email === null && !email?.includes(' ')) {
    emailErrorMessage = ''
  }

  if (password == '' || password?.trim()?.length == 0) {
    passwordErrorMessage = ValidationConstants.emptyPassword
  } else if (!validatePassword(password)) {
    passwordErrorMessage = ValidationConstants.invalidPassword
  }

  return { email: emailErrorMessage, password: passwordErrorMessage }
}

export const forgotPasswordCheck = ({ email }, ValidationConstants) => {
  let showErrorMessage = ''
  if (email == '' || email.trim().length === 0) {
    showErrorMessage = ValidationConstants.emptyEmail
  } else if (
    email.charAt(0) == '.' ||
    email.charAt(0) === '-' ||
    email.charAt(0) === '_' ||
    email.charAt(0) == '@'
  ) {
    showErrorMessage = ValidationConstants.invalidEmail
  } else if (email.includes(' ')) {
    showErrorMessage = ValidationConstants.invalidEmail
  } else if (!validateEmail(email.trim())) {
    showErrorMessage = ValidationConstants.invalidEmail
  }
  return { email: showErrorMessage }
}

export const changePasswordCheck = (field, ValidationConstants) => {
  const { oPass, nPass, cNewPass } = field
  let obj = { oldPassword: '', newPassword: '', confirmPassword: '' }

  if (oPass == '' || oPass?.trim().length == 0) {
    obj.oldPassword = ValidationConstants.emptyOldPassword
  } else if (!validatePassword(oPass)) {
    obj.oldPassword = ValidationConstants.invalidPassword
  }

  if (nPass == '' || nPass?.trim()?.length == 0) {
    obj.newPassword = ValidationConstants.emptyNewPassword
  } else if (!validatePassword(nPass)) {
    obj.newPassword = ValidationConstants.invalidPassword
  } else if (nPass?.length < 6) {
    obj.newPassword = ValidationConstants.shortPassword
  }

  if (cNewPass == '' || cNewPass?.trim().length == 0) {
    obj.confirmPassword = ValidationConstants.emptyConfirmPassword
  } else if (nPass !== cNewPass) {
    obj.confirmPassword = ValidationConstants.notSamePassword
  } else if (!validatePassword(cNewPass)) {
    obj.confirmPassword = ValidationConstants.notSamePassword
  }

  return obj
}

export const resetValidation = (field, ValidationConstants) => {
  const { nPass, cNewPass } = field
  let obj = { newPassword: '', confirmPassword: '' }

  if (nPass === null) {
    obj.newPassword = ''
  }

  if (nPass == '' || nPass?.trim()?.length == 0) {
    obj.newPassword = ValidationConstants.emptyNewPassword
  } else if (!validatePassword(nPass)) {
    obj.newPassword = ValidationConstants.invalidPassword
  } else if (nPass?.length < 6) {
    obj.newPassword = ValidationConstants.shortPassword
  }

  if (cNewPass === null) {
    obj.confirmPassword = ''
  }

  if (cNewPass == '' || cNewPass?.trim()?.length == 0) {
    obj.confirmPassword = ValidationConstants.emptyConfirmPassword
  } else if (nPass !== cNewPass) {
    obj.confirmPassword = ValidationConstants.notSamePassword
  }

  return obj
}

import { put, call, takeLatest } from 'redux-saga/effects'
import {
  loginApi,
  forgotPasswordApi,
  logoutApi,
  changePasswordApi,
  verifyAccountApi,
  resetPasswordApi,
  resetPasswordLinkApi,
} from '../axios/axiosApi'
import ApiConstants from '../../themes/apiConstants'
import { DataManager } from '../../helper/dataManager'
import { navigate } from '../../Route/navigationServices'
import ValidationConstants from '../../themes/validationConstants'
import { toast } from 'react-toastify'
import { constantRoutes } from '../../constants/constantRoutes'

export function sessionOut(msg) {
  DataManager.removeData()
  navigate(constantRoutes.login)
  toast(msg, { toastId: 'session-error' })
}


function* loginApiSaga(action) {
  try {
    let response = yield call(loginApi, action.payload)
    let { result, status } = response

    if (status === 1) {
      DataManager.setAccessToken(result.data.token)
      DataManager.setUserType(result.data.type)
      navigate(constantRoutes.dashboard)
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      toast(result?.msg, { toastId: 'email-password-error' })
      yield put({ type: ApiConstants.API_LOGIN_ERROR })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
    yield put({ type: ApiConstants.API_LOGIN_ERROR })
  }
}

function* forgotPasswordSaga(action) {
  try {
    let response = yield call(forgotPasswordApi, action.payload)
    let { result, status } = response
    if (status === 1) {
      navigate(constantRoutes.login)
      toast(result?.message)
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      toast(result?.msg, { toastId: 'forgot-passowrd-error' })
      yield put({ type: ApiConstants.API_FORGOT_PASSWORD_ERROR })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
    yield put({ type: ApiConstants.API_FORGOT_PASSWORD_ERROR })
  }
}

function* logoutApiSaga(action) {
  try {
    let response = yield call(logoutApi)
    let { result, status } = response
    if (status === 1) {
      DataManager.removeData()
      navigate(constantRoutes.login)
      toast(result?.message)
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      toast(result?.msg, { toastId: 'logout-error' })
      yield put({ type: ApiConstants.API_LOGOUT_ERROR })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
    yield put({ type: ApiConstants.API_LOGOUT_ERROR })
  }
}

function* changePasswordSaga(action) {
  try {
    let response = yield call(changePasswordApi, action?.payload)
    let { result, status } = response

    if (status === 1) {
      DataManager.removeData()
      navigate(constantRoutes.login)
      toast(result?.message)
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      toast(result?.msg, { toastId: 'Change-passowrd-error' })
    }
  } catch (error) {
    toast(ValidationConstants.somethingWorng)
  }
}

function* resetPasswordLinkSaga(action) {
  try {
    let response = yield call(resetPasswordLinkApi, action.payload)
    let { result, status } = response

    if (status === 1) {
      yield put({
        type: ApiConstants.API_RESETLINK_SUCCESS,
        payload: { onLoad: result?.success, userData: null }
      })
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      yield put({
        type: ApiConstants.API_RESETLINK_SUCCESS,
        payload: { onLoad: false, userData: result?.msg }
      })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
  }
}

function* resetPasswordSaga(action) {

  try {
    let response = yield call(resetPasswordApi, action.payload)
    let { result, status } = response

    if (status === 1) {
      yield put({
        type: ApiConstants.API_RESET_SUCCESS,
        payload: { onLoad: result?.success, userData: result?.message }
      })
      toast(result?.message)
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      toast(result?.msg, { toastId: 'Reset-passowrd-error' })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
  }
}

function* verifyAccountSaga(action) {
  try {
    let response = yield call(verifyAccountApi, action.payload)
    let { result, status } = response

    if (status === 1) {
      yield put({
        type: ApiConstants.API_RESETLINK_SUCCESS,
        payload: { onLoad: result?.success, userData: result?.message }
      })
    } else if (status === 2) {
      yield call(sessionOut, result.msg)
    } else {
      yield put({
        type: ApiConstants.API_RESETLINK_SUCCESS,
        payload: { onLoad: false, userData: result?.msg }
      })
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck)
  }
}

export default function* rootAuthenticationSaga() {
  yield takeLatest(ApiConstants.API_LOGIN_LOAD, loginApiSaga)
  yield takeLatest(ApiConstants.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga)
  yield takeLatest(ApiConstants.API_LOGOUT_LOAD, logoutApiSaga)
  yield takeLatest(ApiConstants.API_CHANGE_PASSWORD_LOAD, changePasswordSaga)
  yield takeLatest(ApiConstants.API_RESETLINK_LOAD, resetPasswordLinkSaga)
  yield takeLatest(ApiConstants.API_RESET_LOAD, resetPasswordSaga)
  yield takeLatest(ApiConstants.API_VERIFY_ACCOUNT_LOAD, verifyAccountSaga)
}

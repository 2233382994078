import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authSaga";
import rootUserSaga from "./userSaga";
import rootEventSaga from "./eventSaga";
import rootPostSaga from "./postSaga";
import rootDashboardSaga from "./dashboardSaga";

export default function* rootSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootEventSaga),
    fork(rootUserSaga),
    fork(rootPostSaga),
    fork(rootDashboardSaga),
  ]);
}

import React, { useEffect, useState } from "react";
import { Table, Modal, Radio, Space, Button, Dropdown, Menu, Select } from "antd";
import { navigate } from "../../Route/navigationServices.js";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { isInternetConnected } from "../../helper/networkUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  blockAction,
  userListAction,
  changeRoleAction,
  deleteUserAction
} from "../../redux/actions/userActions.js";
import ValidationConstants from "../../themes/validationConstants";
import Textfield from "../../common/index";
import moment from "moment";
import { time_dateFormat, sortValue } from "../../constants/constantValues";
import { constantRoutes } from "../../constants/constantRoutes";
import ApiConstants from "../../themes/apiConstants";
import { ValidationFunction } from "../../themes/validationFunctions";
import { pageDetails } from "../../redux/actions/authActions";
import BreadCrumb from "../../common/breadCrumb.js";
import * as Yup from 'yup'
import { capitalizeFirstLetter, whitespacesRgx } from '../../utils/validator'
import { constValues } from '../../constants/constantValues'

const validationSchemaForEmailPass = Yup.object().shape({
  email: Yup.string().email().required(ValidationFunction.emptyField(appconstant.NewPassword.toLocaleLowerCase())),
  pass: Yup.string()
    .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.NewPassword)))
    .min(constValues.passMinLength, ValidationFunction.minlength(capitalizeFirstLetter(appconstant.NewPassword), constValues.passMinLength))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, ValidationFunction.password(capitalizeFirstLetter(appconstant.NewPassword)))
    .required(ValidationFunction.emptyField(appconstant.NewPassword.toLocaleLowerCase())),
})

const UserManagement = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [deleteText, setDeleteText] = useState("")
  const [reason, setReason] = useState("Policy Violator")
  const [value, setValue] = useState('user')
  const [record, setRecord] = useState('')
  const [isEmailUI, setIsEmailUI] = useState(false)
  const [emailPass, setEmailPass] = useState({ email: '', pass: '' })
  const pageDetail = useSelector(
    (state) => state.authenticationReducer.pageDetials
  );
  const list = useSelector((state) => state.userManagementReducer.userData);
  const roleUpdate = useSelector((state) => state.userManagementReducer.roleUpdate);
  const getValue = (record) => {
    if (record.isAmbassador) {
      return "Ambassador"
    }
    else if (record.isAdmin) {
      return "Admin"
    }
    else {
      return record.membership ? "Member" :"Guest"
    }


  }
  const disableModel = (record) => {
    let data = record?.isBlock ? appconstant.unBlock : appconstant.block;
    Modal.confirm({
      title: data,
      content: ValidationFunction.modalContent(
        data.toLocaleLowerCase(),
        appconstant.user.toLocaleLowerCase()
      ),
      okText: appconstant.Yes,
      centered: true,
      cancelText: appconstant.No,
      onOk() {
        if (isInternetConnected()) {
          dispatch(
            blockAction([`admin/changeUserStatus/${record?._id}`, pageDetail])
          );
        } else {
          toast(ValidationConstants.internetCheck, { id: "user-index" });
        }
      },
      onCancel() { },
      className: "new-button",
    });
  };

  useEffect(() => {
    if (roleUpdate) {
      if (isInternetConnected()) {
        dispatch(
          userListAction({
            offset: 0,
            limit: 10,
            sort: "",
            order: "",
            search: "",
          })
        );
        dispatch(
          pageDetails({
            offset: 0,
            order: "",
            search: "",
            filter: moment(pageDetail?.date).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
            current: 1,
          })
        );
        dispatch({ type: ApiConstants.API_VIEW_SUCCESS, payload: null });
      } else {
        toast(ValidationConstants.internetCheck, { id: "user-index" });
      }
    }
  }, [roleUpdate])

  const items = [
    {
      key: '1',
      label: 65465,
    },
    {
      key: '2',
      label: 65465,
    },
    {
      key: '3',
      label: 65465,
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      width: "68px",
      sorter: false,
      render: (value, item, index) => index + 1 + pageDetail?.offset,
    },
    {
      width: "120px",
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
      render: (value) => (value ? value : appconstant.NA),
    },
    {
      width: "150px",
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (value) => (value ? value : appconstant.NA),
    },
    {
      width: "140px",
      title: "Date of Joining",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value) => {
        return value
          ? moment(value).utc().format(time_dateFormat.dateFormat)
          : null;
      },
    },
    {
      width: "140px",
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      sorter: true,
      render: (value) => {
        return value ? moment(value).format(time_dateFormat.dateFormat) : "N/A";
      },
    },
    {
      width: "135px",
      title: "No. of Posts",
      dataIndex: "posts",
      key: "posts",
      sorter: true,
    },
    {
      width: "135px",
      title: "No. of Friends",
      dataIndex: "friends",
      key: "friends",
      sorter: true,
    },
    {
      width: 180,
      title: appconstant.ambassador,
      key: "isAmbassador",
      dataIndex: "isAmbassador",
      sorter: true,
      render: (isAmbassador, value) => (
        <span>{value.isAmbassador ? "Yes" : "No"}</span>

      ),
    },
    {
      width: 180,
      title: appconstant.member,
      key: appconstant?.memberShip?.toLocaleLowerCase(),
      sorter: false,
      render: (item, value) => (
        <span>
          {appconstant.packages[0] == value?.membership?.name?.toLocaleLowerCase() ? "Frendii Social"
            : appconstant.packages[1] == value?.membership?.name?.toLocaleLowerCase() ? "Frendii Social Plus"
              : appconstant.packages[2] == value?.membership?.name?.toLocaleLowerCase() ? "Frendii Wellness"
                : appconstant.NA}
        </span>
      ),
    },
    {
      width: "135px",
      title: "User Role",
      dataIndex: "friends",
      key: "friends",
      sorter: false,
      render: (item, value) => (
        <span>
          {getValue(value)}
        </span>
      ),
    },
    {
      width: "350px",
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (_, record, index) => {

        return (
          <div>
            {/* <button style={{ width: 'fit-content' }} onClick={() => {
              setValue(record.isAmbassador ? "ambassador" : record.isAdmin ? "admin" : 'user')
              setRecord(record)
              setIsEmailUI(false)
              setEmailPass({email:'',pass:''})
              setIsVisible(true)
            }}>
              {appconstant.changeRole}
            </button> */}
            <>
              <button onClick={() => view(record)} type="submit">
                {appconstant.view}
              </button>
              <Dropdown

                overlay={<Menu>
                  <Menu.Item><button className="dropDownBtn" style={{ width: 'fit-content' }} onClick={() => {
                    setValue(record.isAmbassador ? "ambassador" : record.isAdmin ? "admin" : 'user')
                    setRecord(record)
                    setIsEmailUI(false)
                    setEmailPass({ email: '', pass: '' })
                    setIsVisible(true)
                  }}>
                    {appconstant.changeRole}
                  </button></Menu.Item>
                  <Menu.Item><button className="dropDownBtn" onClick={() => edit(record)} type="submit">
                    {appconstant.edit}
                  </button></Menu.Item>
                  <Menu.Item><button className="dropDownBtn" type="submit" onClick={() => disableModel(record)}>
                    {record.isBlock ? appconstant.unBlock : appconstant.block}
                  </button></Menu.Item>
                  <Menu.Item><button className="dropDownBtn" type="submit" onClick={() => {
                    setIsDeleteVisible(true)
                    setRecord(record)
                  }
                  }>
                    {appconstant.delete}
                  </button></Menu.Item>
                </Menu>}
              >
                <Button>...</Button>
              </Dropdown>


            </>
            {/* <button onClick={() => edit(record)} type="submit">
              {appconstant.edit}
            </button>
            <button type="submit" onClick={() => disableModel(record)}>
              {record.isBlock ? appconstant.unBlock : appconstant.block}
            </button> */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isInternetConnected()) {
      dispatch(
        userListAction({
          offset: 0,
          limit: 10,
          sort: "",
          order: "",
          search: "",
        })
      );
      dispatch(
        pageDetails({
          offset: 0,
          order: "",
          search: "",
          filter: moment(pageDetail?.date).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
          current: 1,
        })
      );
      dispatch({ type: ApiConstants.API_VIEW_SUCCESS, payload: null });
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  }, []);

  const view = (record) => {
    if (isInternetConnected()) {
      localStorage.setItem("userId", JSON.stringify(record?._id));
      navigate(constantRoutes.userdetails);
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };
  const edit = (record) => {
    if (isInternetConnected()) {
      localStorage.setItem("userId", JSON.stringify(record?._id));
      navigate(constantRoutes.edituserdetails);
    } else {
      toast(ValidationConstants.internetCheck, { toastId: "user-index" });
    }
  };
  const tableChanges = (p, _, c) => {
    if (isInternetConnected()) {
      dispatch(
        userListAction({
          offset: (p.current - 1) * 10,
          limit: 10,
          sort: sortValue(c.order) ? c.field : "",
          order: sortValue(c.order),
          search: pageDetail?.search,
        })
      );
      dispatch(
        pageDetails({
          offset: (p.current - 1) * 10,
          order: sortValue(c.order),
          search: pageDetail?.search,
          sortBy: sortValue(c.order) ? c.field : "",
          filter: pageDetail?.filter,
          current: p?.current,
        })
      );
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };
  const onSearch = (e) => {
    if (e.target.value[0] === " ") {
      return;
    }

    if (isInternetConnected()) {
      dispatch(
        userListAction({
          offset: 0,
          limit: 10,
          sort: pageDetail?.sortBy,
          order: pageDetail?.order,
          search: e.target.value,
        })
      );
      dispatch(
        pageDetails({
          offset: pageDetail?.offset,
          order: pageDetail?.order,
          search: e.target.value,
          sort: pageDetail?.sortBy,
          filter: pageDetail?.filter,
          current: 1,
        })
      );
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };

  const onChangeRole = () => {
    if (isInternetConnected()) {
      dispatch(
        changeRoleAction({ userId: record._id, type: value, email: emailPass.email, password: emailPass.pass })
      );
      setIsVisible(false)
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };
  console.log(deleteText, "deleteText");
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: "50%" }}>
          <BreadCrumb currentCrumb={appconstant.UserManagement} />
        </div>

        <div className="addBtnDiv">
          <button
            onClick={() => navigate(constantRoutes.addMember)}
            className="button-listAdd"
            type="submit"
          >
            {appconstant.addMember}
          </button>
        </div>
      </div>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.UserManagement}
            </h6>
            <Textfield
              type="text"
              className="iocn-search search-bar"
              placeholder={appconstant.search}
              value={pageDetail?.search}
              onChange={onSearch}
            />
          </div>
          <Table
            dataSource={list?.list}
            columns={columns}
            showSorterTooltip={false}
            onChange={tableChanges}
            pagination={{
              total: list?.pagination?.totalCount,
              current: pageDetail?.current,
            }}
          />
        </div>
        {isVisible &&
          <Modal
            maskClosable={false}
            centered
            title={"Change Role"}
            className="forgot-success"
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            onOk={() => {
              if (value === 'user' || record?.email) {
                onChangeRole();
              }
              else {
                if (isEmailUI) {
                  validationSchemaForEmailPass.validate(emailPass, { abortEarly: false })
                    .then(function (valid) {
                      onChangeRole();
                    })
                    .catch(function (error) {
                      toast(error?.errors[0], { toastId: 'userList-passowrd-error' })
                    })
                    ;
                }
                else setIsEmailUI(true)
              }
            }
            }
          >
            {isEmailUI ?
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginRight: 8 }}>{appconstant.EmailAddress}</label>
                  <Textfield
                    className='form-control'
                    type='text'
                    placeholder={appconstant.EmailAddress}
                    value={emailPass.email}
                    onChange={(e) => setEmailPass({ ...emailPass, email: e.target.value })}
                    containerStyle={{ margin: 'unset' }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                  <label style={{ marginRight: 8 }}>{appconstant.Password}</label>
                  <Textfield
                    className='form-control'
                    type='password'
                    placeholder={appconstant.Password}
                    value={emailPass.pass}
                    onChange={(e) => setEmailPass({ ...emailPass, pass: e.target.value })}
                    containerStyle={{ margin: 'unset' }}
                  />
                </div>
              </div>
              : <div>
                <Radio.Group name="radiogroup" value={value} onChange={(e) => setValue(e.target.value)}>
                  <Space direction="vertical">
                    <Radio value={"admin"}>Admin</Radio>
                    <Radio value={"ambassador"}>Frendii Ambassador</Radio>
                    <Radio value={"user"}>Frendii Guest</Radio>
                  </Space>
                </Radio.Group>
              </div>}
          </Modal>
        }

        {isDeleteVisible &&
          <Modal
            maskClosable={false}
            centered
            title={""}
            className="forgot-success"
            footer={[]}
            visible={isDeleteVisible}
            onCancel={() => {
              setIsDeleteVisible(false)
              setDeleteText("")
            }}
            onOk={() => {
              dispatch(
                deleteUserAction(
                  [record?._id, pageDetail])
              );
            }
            }
          >
            <div>
              <h3 style={{ fontSize: 15, fontWeight: "700", lineHeight: '15px' }}>Delete the User?</h3>
              <h5 style={{ fontSize: 14, fontWeight: "400", lineHeight: '15px' }}>This action is irreversible and user cannot be recovered.</h5>
              <div style={{
                gap: "10px",
                display: "flex",
                height: "auto",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <span style={{ fontSize: 14, fontWeight: "400", lineHeight: '15px' }}>Select reason for deletion:</span>
                <Select
                  placeholder="Select"
                  style={{ maxWidth: 200 }}
                  onChange={(e) => setReason(e)}
                  value={reason}
                  options={[
                    {
                      value: 'Policy Violator',
                      label: 'Policy Violator',
                    },
                    {
                      value: 'User Requested',
                      label: 'User Requested',
                    },
                    {
                      value: 'Test User',
                      label: 'Test User',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                />
              </div>
              <div className="delete-alert">

                <h5 style={{ fontSize: 14, fontWeight: "400", lineHeight: '15px' }}>Write the word<strong> delete </strong>to confirm deletion</h5>
                <input type="text" placeholder="delete" onChange={(e) => setDeleteText(e.target.value)} value={deleteText} />
                <div className="delet-alert-btn">
                  <button className="delete-btn1" onClick={() => {
                    setIsDeleteVisible(false)
                    setDeleteText("")
                  }}>
                    {"Cancel"}
                  </button>
                  <button disabled={deleteText !== "delete"} style={{cursor:deleteText == "delete" ?
                  "pointer":'no-drop'}} className="delete-btn2" onClick={() => {
                    dispatch(
                      deleteUserAction(
                        [{userId:record?._id,reason:reason}, pageDetail])
                    );
                    setIsDeleteVisible(false)
                    setDeleteText("")
                  }} >
                    {appconstant.deleteP}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        }
      </div>
    </div>
  );
};
export default UserManagement;

import React from 'react'
import images from '../themes/appImage'
import '../css/style.css'

const ExpiredToken = ({ props }) => {
  return (
    <div className='expirePage-header'>
      <div className='expiredMain-div'>
        <div className='expired-form'>
          <div>
            <img
              alt='expired-panel-img'
              className='expired-logo'
              src={images.login}
            />
            <h3 className='expired-message'>{props.userData}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpiredToken

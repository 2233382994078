import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image } from 'antd'
import appconstant from '../../themes/appconstant'
import Textfield from '../../common'
import images from '../../themes/appImage'
import {
  constValues,
  extentions,
  videoDimentions,
  videoExtension
} from '../../constants/constantValues'
import { useDispatch, useSelector } from 'react-redux'
import { isInternetConnected } from '../../helper/networkUtils'
import { toast } from 'react-toastify'
import ValidationConstants from '../../themes/validationConstants'
import { url } from '../../redux/axios/apikit'
import { constantRoutes } from '../../constants/constantRoutes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ValidationFunction } from '../../themes/validationFunctions'
import { beginningSpace } from '../../utils/validator'
import { postDetailsAction, postUpdateAction } from '../../redux/actions/postActions.js'
import BreadCrumb from '../../common/breadCrumb'

const EditPostDetails = () => {
  const dispatch = useDispatch()
  const [media, setMedia] = useState({
    allFiles: [],
    removeMedia: []
  })
  const [mediaCount, setMediaCount] = useState({})
  const data = useSelector(state => state.postReducer.postDetail)
  const refImage = useRef()
  const refVideo = useRef()
  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .matches(
        beginningSpace,
        ValidationFunction.validField(appconstant.description.toLocaleLowerCase())
      )
      .required(ValidationConstants.descriptionRequired)
      .min(3, ValidationFunction.minlength(appconstant.description)),
    title: Yup.string()
      .required(ValidationConstants.titleRequired)
      .matches(
        beginningSpace,
        ValidationFunction.validField(appconstant.title.toLocaleLowerCase())
      )
      .min(3, ValidationFunction.minlength(appconstant.title))
  })

  const onSubmit = () => {
    const body = new FormData()
    body.append('title', formik.values.title)
    body.append('postId', localStorage.getItem('postId'))
    body.append('description', formik.values.description)
    body.append('removeMedia', JSON.stringify(media.removeMedia))
    if (media?.allFiles) {
      for (const file of media?.allFiles) {
        body.append('files', file)
      }
    }
    if (isInternetConnected()) {
      if (!Object.values(formik.errors).join('')) {
        if (media?.allFiles.length !== 0) {
          dispatch(postUpdateAction(body))
        } else {
          toast(ValidationConstants.minimunMedia, { toastId: 32 })
        }
      }
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 35 })
    }
  }
  const files = e => {
    if (
      e.target.files[0]?.type === extentions.jpeg ||
      e.target.files[0]?.type === extentions.png ||
      e.target.files[0]?.type === extentions.jpg
    ) {
      if (e.target.files[0]?.size < constValues.imageSize) {
        setMedia(state => {
          return {
            ...state,
            allFiles: [...state.allFiles, e.target.files[0]]
          }
        })
      } else {
        toast(ValidationConstants.fileSize)
      }
    } else {
      toast(ValidationConstants.fileExtention)
    }
  }
  const videoFile = e => {
    const reader = new FileReader()
    reader.onload = function () {
      const mediaData = new Audio(reader.result)
      mediaData.onloadedmetadata = function () {
        if (e.target.files[0].type === videoExtension.mp4) {
          if (mediaData.duration <= constValues.videoDurationLimit) {
            setMedia(state => {
              return {
                ...state,
                allFiles: [...state.allFiles, e.target.files[0]]
              }
            })
          } else {
            toast(ValidationConstants.clipDuration, { toastId: 34 })
          }
        } else {
          toast(ValidationConstants.videoExtension, { toastId: 35 })
        }
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }
  const remove = (value, picIndex) => {
    setMedia(state => {
      return {
        ...state,
        allFiles: state?.allFiles?.filter((i, index) => index !== picIndex),
        removeMedia: [...state.removeMedia, value?._id]
      }
    })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: data?.title,
      description: data?.description
    },
    validationSchema
  })

  useEffect(() => {
    if (isInternetConnected()) {
      dispatch(postDetailsAction(localStorage.getItem('postId')))
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 32 })
    }
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    setMediaCount({
      videos: media?.allFiles?.filter(
        value =>
          value?.type === 'video' ||
          Object.values(videoExtension).includes(value?.type)
      ).length,
      images: media?.allFiles?.filter(
        value =>
          value?.type === 'image' ||
          Object.values(extentions).includes(value?.type)
      ).length
    })
  }, [media.allFiles])
  useMemo(() => {
    setMedia(state => {
      return { ...state, allFiles: data?.media }
    })
  }, [data])

  const imageFragment = media?.allFiles?.map((value, index) => {
    return value?.type === 'image' ||
      Object.values(extentions).includes(value?.type) ? (
      <div key={index}>
        <img
          className='datacenter-vector'
          src={images.vector1}
          onClick={() => remove(value, index)}
        />
        <Image
          preview={false}
          src={
            value?.path
              ? url + value?.path
              : value
                ? URL?.createObjectURL(value)
                : null
          }
        />
      </div>
    ) : null
  })
  const videoFragment = media?.allFiles?.map((value, index) => {
    return value?.type === 'video' ||
      Object.values(videoExtension).includes(value?.type) ? (
      <div key={index} className='cross'>
        <div className='img-div'>
          <img
            className='datacenter-vector-video'
            src={images.vector1}
            onClick={() => remove(value, index)}
          />
        </div>
        <video
          src={
            value?.path
              ? url + value.path
              : value
                ? URL.createObjectURL(value)
                : null
          }
          {...videoDimentions}
          controls
        />
      </div>
    ) : null
  })

  return (
    <div>
      <BreadCrumb firstCrumb={{ link: constantRoutes.postmanagement, pathName: appconstant.postManagement }} currentCrumb={appconstant.postDetails} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.editPostDetails}
            </h6>
          </div>
          <br />
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className='wrapper_line wrapper-top view_modules view '>
              <div className='form-group'>
                <label>{appconstant.title}</label>
                <Textfield
                  className='form-control'
                  type='text'
                  defaultValue={data?.title}
                  placeholder={appconstant.title}
                  onChange={formik.handleChange('title')}
                  value={formik.values.title}
                  error={formik.errors.title}
                  maxLength={100}
                />
              </div>
              <div>
                <div className='form-group'>
                  <label>{appconstant.description}</label>
                  <Textfield
                    className='form-control'
                    type='text'
                    placeholder={appconstant.description}
                    onChange={formik.handleChange('description')}
                    value={formik.values.description}
                    error={formik.errors.description}
                    maxLength={500}
                  />
                </div>
              </div>
              {mediaCount.videos < 5 && (
                <div className='form-images'>
                  <label>{appconstant.image}</label>
                  <div className='inline'>
                    {imageFragment}
                    {mediaCount.images < 5 && media?.allFiles?.length !== 5 && (
                      <div>
                        <Textfield
                          type='image'
                          className='addImage'
                          src={images.addImage}
                          title={appconstant.addImage}
                          onClick={() => refImage.current.click()}
                        />
                        <input
                          onClick={e => (e.target.value = null)}
                          accept={Object.values(extentions)}
                          style={{ display: 'none' }}
                          onChange={files}
                          ref={refImage}
                          type='file'
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                {mediaCount.images < 5 && (
                  <div className='form-videos'>
                    <label>{appconstant.video}</label>
                    <div className='video-clips'>
                      {videoFragment}
                      {mediaCount.videos < 5 && media?.allFiles?.length !== 5 && (
                        <div className='cross'>
                          <div className='img-div'>
                            <Textfield
                              type='image'
                              src={images.addImage}
                              className='addImage-video'
                              title={appconstant.addVideo}
                              onClick={() => refVideo.current.click()}
                            />
                            <input
                              accept={Object.values(videoExtension)}
                              onClick={e => (e.target.value = null)}
                              style={{ display: 'none' }}
                              onChange={videoFile}
                              ref={refVideo}
                              type='file'
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='up-btn'>
              <button onClick={onSubmit} type='submit' className='button-list'>
                {appconstant.buttonupate}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default EditPostDetails

import { Select } from 'antd'
import React from 'react'
import { capitalizeFirstLetter } from '../utils/validator'

const MultiSelect = props => {
  const {
    value,
    disabled,
    showArrow,
    onChange,
    OPTIONS,
    className,
    error,
    placeholder
  } = props
  const filteredOptions = OPTIONS?.filter(o => !value?.includes(o))

  return (
    <div className='input_fields'>
      <Select
        className={className}
        mode='multiple'
        showArrow={showArrow}
        placeholder={placeholder || 'Interests'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        tagRender={'name'}
      >
        {filteredOptions?.map(item => (
          <Select.Option key={item?._id} value={item?._id}>
            {capitalizeFirstLetter(item?.name)}
          </Select.Option>
        ))}
      </Select>
      {<span className='login-error'>{error ? error : ''}</span>}
    </div>
  )
}

export default MultiSelect

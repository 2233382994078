import React, { useEffect } from 'react'
import images from '../../themes/appImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import ValidationConstants from '../../themes/validationConstants'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Textfield from '../../common'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isInternetConnected } from '../../helper/networkUtils'
import { navigate } from '../../Route/navigationServices'
import { validateEmailRgx } from '../../utils/validator'
import { ValidationFunction } from '../../themes/validationFunctions'
import appconstant from '../../themes/appconstant'
import { forgotPasswordAction } from '../../redux/actions/authActions.js'

const ForgotPassword = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = value => {
    if (isInternetConnected()) {
      dispatch(forgotPasswordAction(value))
    } else {
      toast(ValidationConstants.internetCheck)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        validateEmailRgx,
        ValidationFunction.validField(appconstant.EmailAddress.toLowerCase())
      )
      .required(ValidationFunction.emptyField(appconstant.EmailAddress.toLowerCase()))
  })

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit
  })

  return (
    <div className='image-bg'>
      <div className='page-header'>
        <div className='main-div'>
          <div className='login-form mt-top'>
            <div className='back'>
              <button
                onClick={() => navigate('login')}
                type='submit'
                className=' btn2'
              >
                <FontAwesomeIcon
                  className='icon-angle-left'
                  icon={faAngleLeft}
                />
              </button>
            </div>
            <div>
              <img src={images.login} />
            </div>
            <h4>{appconstant.ForgotPassword}</h4>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className='user'>
                <Textfield
                  onChange={formik.handleChange('email')}
                  placeholder={appconstant.EmailAddress}
                  value={formik.values.email.trim()}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div>
                <button type='submit' className='login-link'>
                  {appconstant.Send}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgotPassword

import React, { useEffect, } from 'react'
import { Table, Modal } from 'antd'
import appconstant from '../../themes/appconstant'
import { navigate } from '../../Route/navigationServices'
import { useDispatch, useSelector } from 'react-redux'
import { isInternetConnected } from '../../helper/networkUtils'
import ValidationConstants from '../../themes/validationConstants'
import { toast } from 'react-toastify'
import moment from 'moment'
import { time_dateFormat, sortValue } from '../../constants/constantValues'
import Textfield from '../../common'
import { constantRoutes } from '../../constants/constantRoutes'
import { ValidationFunction } from '../../themes/validationFunctions'
import { postBlockAction, postDeleteAction, postListAction } from '../../redux/actions/postActions.js'
import { pageDetails } from '../../redux/actions/authActions.js'
import BreadCrumb from '../../common/breadCrumb'

const PostManagement = () => {
  const dispatch = useDispatch()
  const pageDetail = useSelector(
    state => state.authenticationReducer.pageDetials
  )
  const data = useSelector(state => state.postReducer.postList)

  const disableModel = item => {
    let data = item?.isBlock ? appconstant.unBlock : appconstant.block
    Modal.confirm({
      title: data,
      content: ValidationFunction.modalContent(data.toLocaleLowerCase(), appconstant.post.toLocaleLowerCase()),
      okText: appconstant.Yes,
      centered: true,
      cancelText: appconstant.No,
      onOk() {
        if (isInternetConnected()) {
          dispatch(postBlockAction({ id: item?._id, onload: pageDetail }))
        } else {
          toast(ValidationConstants.internetCheck, { toastId: 'HR' })
        }
      },
      onCancel() { },
      className: 'new-button'
    })
  }
  const deleteModel = item => {
    Modal.confirm({
      title: appconstant.delete,
      content: ValidationFunction.modalContent(appconstant.delete.toLocaleLowerCase(), appconstant.post.toLocaleLowerCase()),
      okText: appconstant.Yes,
      centered: true,
      cancelText: appconstant.No,
      onOk() {
        if (isInternetConnected()) {
          dispatch(postDeleteAction({ id: item?._id, onload: pageDetail }))
        } else {
          toast(ValidationConstants.internetCheck, {
            toastId: 'eventview-error'
          })
        }
      },
      onCancel() { },
      className: 'new-button'
    })
  }

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'srno',
      key: 'srno',
      sorter: false,
      render: (value, item, index) =>
        index + 1 + (data?.pagination?.nextPage - 10)
    },
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (value, item, index) => item?.user?.name
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (value, item, index) => item?.user?.email || appconstant.NA
    },
    {
      title: 'Date of Post',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: value =>
        value
          ? moment(value)
            .utc()
            .format(time_dateFormat.dateFormat)
          : null
    },
    {
      title: 'No. of Comments',
      dataIndex: 'comments',
      key: 'comments',
      sorter: true
    },
    {
      title: 'No. of Likes',
      dataIndex: 'likes',
      key: 'likes',
      sorter: true
    },
    {
      title: 'Action',
      dataIndex: 'Posted',
      key: 'Posted',
      render: (_, item, index) => {
        return (
          <div>
            <button
              onClick={() => {
                JSON.stringify(localStorage.setItem('postId', item?._id))
                navigate(constantRoutes.postdetails)
              }}
            >
              {appconstant.view}
            </button>
            <button
              onClick={() => {
                JSON.stringify(localStorage.setItem('postId', item?._id))
                navigate(constantRoutes.editPostDetails)
              }}
            >
              {appconstant.edit}
            </button>
            <button onClick={() => disableModel(item)}>
              {item?.isBlock ? appconstant.unBlock : appconstant.block}
            </button>
            <button onClick={() => deleteModel(item)}>
              {appconstant.delete}
            </button>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isInternetConnected()) {
      dispatch(
        pageDetails({
          offset: 0,
          order: '',
          search: '',
          sort: '',
          current: 1
        })
      )
      dispatch(
        postListAction({
          limit: 10,
          offset: 0,
          sort: '',
          search: '',
          order: ''
        })
      )
    } else {
      toast(ValidationConstants.internetCheck, { toastId: '3' })
    }
  }, [])

  const tableChanges = (p, _, c) => {

    if (isInternetConnected()) {
      dispatch(
        postListAction({
          offset: (p.current - 1) * 10,
          limit: 10,
          sort: sortValue(c.order) ? c.field : '',
          order: sortValue(c.order),
          search: pageDetail?.search,
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
      dispatch(
        pageDetails({
          offset: (p.current - 1) * 10,
          order: sortValue(c.order),
          search: pageDetail?.search,
          sortBy: sortValue(c.order) ? c.field : '',
          current: p?.current,
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
    } else {
      toast(ValidationConstants.internetCheck, { id: 'user-index' })
    }
  }

  const onSearch = e => {
    if (e.target.value[0] === ' ') {
      return
    }

    if (isInternetConnected()) {
      dispatch(
        postListAction({
          offset: 0,
          limit: 10,
          sort: '',
          order: '',
          search: e.target.value,
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
      dispatch(
        pageDetails({
          offset: 0,
          order: '',
          sort: '',
          current: 1,
          search: e.target.value,
          date: moment(moment(pageDetail?.date).format("YYYY-MM-DD")).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
      )
    }
  }

  return (
    <div>
      <BreadCrumb currentCrumb={appconstant.postManagement} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.postManagement}
            </h6>
            <Textfield
              onChange={onSearch}
              type='text'
              className='iocn-search search-bar'
              placeholder={appconstant.search}
              value={pageDetail?.search}
            />
          </div>
          <Table
            dataSource={data?.list}
            columns={columns}
            showSorterTooltip={false}
            onChange={tableChanges}
            pagination={{
              total: data?.pagination?.totalCount,
              current: data?.pagination.currentPage
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default PostManagement

import React from "react";
import { TimePicker, Space } from "antd";

const timePicker = (props) => {
  const {
    placeholder,
    onChange,
    inputReadOnly,
    format,
    defaultValue,
    className,
    error,
    value,
    showNow = true,
  } = props;
  return (
    <Space className="input_fields" direction="vertical">
      <TimePicker
        className={className}
        placeholder={placeholder}
        inputReadOnly={inputReadOnly}
        onChange={onChange}
        format={format}
        defaultValue={defaultValue}
        value={value}
        showNow={showNow}
      />
      <span className="login-error">{error ? error : ""}</span>
    </Space>
  );
};

export default timePicker;

import React, { useEffect } from "react";
import { DatePicker, Space, Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../Route/navigationServices";
import moment from "moment";
import { time_dateFormat, sortValue } from "../../constants/constantValues";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ValidationConstants from "../../themes/validationConstants";
import { isInternetConnected } from "../../helper/networkUtils";
import { constantRoutes } from "../../constants/constantRoutes";
import Textfield from "../../common";
import { ValidationFunction } from "../../themes/validationFunctions";
import {
  deleteEventAction,
  eventManagementAction,
} from "../../redux/actions/eventActions.js";
import { pageDetails } from "../../redux/actions/authActions.js";
import BreadCrumb from "../../common/breadCrumb";

var selecteddate = moment(new Date(), "YYYY-MM")
const EventManagement = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.eventReducer.eventData);
  const pageDetail = useSelector(
    (state) => state.authenticationReducer.pageDetials
  );

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      width: "60px",
      sorter: false,
      render: (value, item, index) =>
        index + 1 + (data?.pagination?.nextPage - 10),
    },
    {
      title: "Event Name",
      width: "120px",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value) => (value ? value : "N/A"),
    },
    {
      title: "Start Date ",
      width: "95px",
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      render: (value) => {
        return value
          ? moment(value).local().format(time_dateFormat.dateFormat)
          : null;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: false,
      width: "180px",
      ellipsis: true,
      render: (value, item, index) => {
        return `${item?.address}`;
      },
    },
    {
      width: "250px",
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (_, record, index) => {
        return (
          <div>
            <button
              onClick={() => {
                navigate(constantRoutes.eventdetails);
                localStorage.setItem("eventId", JSON.stringify(record?._id));
              }}
              type="submit"
            >
              {appconstant.view}
            </button>
            <button
              onClick={() => {
                navigate(constantRoutes.editEventDetails);
                localStorage.setItem("eventId", JSON.stringify(record?._id));
              }}
              disabled={!record?.isStarted ? false : true}
              type="submit"
              style={{
                backgroundColor: record?.isStarted ? "grey" : "#ff8032",
                pointerEvents: !record?.isStarted ? "auto" : "none",
              }}
            >
              {appconstant.edit}
            </button>
            <button
              style={{
                backgroundColor: record?.isStarted ? "grey" : "#ff8032",
                pointerEvents: !record?.isStarted ? "auto" : "none",
              }}
              disabled={!record?.isStarted ? false : true}
              type="submit"
              onClick={() => deleteModel(record)}
            >
              {appconstant.delete}
            </button>
          </div>
        );
      },
    },
  ];
  const deleteModel = (record) => {
    Modal.confirm({
      title: appconstant.delete,
      content: ValidationFunction.modalContent(
        appconstant.delete.toLocaleLowerCase(),
        appconstant.event.toLocaleLowerCase()
      ),
      okText: appconstant.Yes,
      centered: true,
      cancelText: appconstant.No,
      onOk() {
        if (isInternetConnected()) {
          const data = pageDetail
          data.limit = 10
          data.search = pageDetail?.search
          data.startDate = moment.utc(moment(selecteddate, "YYYY-MM").startOf("month")).format("YYYY-MM-DDTHH:mm:ss")
          data.endDate = moment.utc(moment(selecteddate, "YYYY-MM").endOf("month")).format("YYYY-MM-DDTHH:mm:ss")
          dispatch(deleteEventAction([record?._id, data]));
        } else {
          toast(ValidationConstants.internetCheck, {
            toastId: "eventview-error",
          });
        }
      },
      onCancel() { },
      className: "new-button",
    });
  };
  const tableChanges = (p, _, c) => {
    if (isInternetConnected()) {
      dispatch(
        eventManagementAction({
          admin:true,
          offset: (p.current - 1) * 10,
          limit: 10,
          sort: sortValue(c.order) ? c.field : "",
          order: sortValue(c.order),
          search: pageDetail?.search,
          startDate: moment
            .utc(moment(pageDetail?.date).format("YYYY-MM-DD"))
            .utc()
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment
            .utc(moment(pageDetail?.date).format("YYYY-MM-DD"))
            .utc()
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),
        })
      );
      dispatch(
        pageDetails({
          offset: (p.current - 1) * 10,
          order: sortValue(c.order),
          search: pageDetail?.search,
          sort: sortValue(c.order) ? c.field : "",
          current: p?.current,
          startDate: moment
            .utc(moment(pageDetail?.date).format("YYYY-MM-DD"))
            .utc()
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment
            .utc(moment(pageDetail?.date).format("YYYY-MM-DD"))
            .utc()
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),

          date: moment
            .utc(moment(pageDetail?.date).format("YYYY-MM-DD"))
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ"),
        })
      );
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };
  const dateChange = (value, dateString) => {
    selecteddate = dateString
    if (isInternetConnected()) {
      dispatch(
        eventManagementAction({
          admin:true,
          offset: 0,
          limit: 10,
          sort: "",
          order: "",
          search: pageDetail?.search,
          date: moment.utc(moment(`${dateString}T${moment().format("HH:mm:ss")}`, "YYYY-MMTHH:mm:ss").startOf("day")),
          startDate: moment.utc(moment(dateString, "YYYY-MM").startOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment.utc(moment(dateString, "YYYY-MM").endOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
        })
      );
      dispatch(
        pageDetails({
          offset: 0,
          order: "",
          search: pageDetail?.search,
          sort: "",
          current: 1,
          date: moment.utc(moment(`${dateString}T${moment().format("HH:mm:ss")}`, "YYYY-MMTHH:mm:ss").startOf("day")),
          startDate: moment.utc(moment(dateString, "DD-MM-YYYY").startOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment.utc(moment(dateString, "DD-MM-YYYY").endOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
        })
      );
    } else {
      toast(ValidationConstants.internetCheck, { id: "user-index" });
    }
  };
  const onSearch = (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    if (isInternetConnected()) {
      dispatch(
        eventManagementAction({
          admin:true,
          offset: 0,
          limit: 10,
          sort: "",
          order: "",
          search: e.target.value ?? "",
          startDate: moment.utc(moment(selecteddate, "YYYY-MM").startOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment.utc(moment(selecteddate, "YYYY-MM").endOf("month")).format("YYYY-MM-DDTHH:mm:ss"),
          date: moment
            .utc(moment(selecteddate).format("YYYY-MM-DD"))
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ"),
        })
      );
      dispatch(
        pageDetails({
          offset: 0,
          order: "",
          sort: "",
          current: 1,
          search: e.target.value,
          date: moment
            .utc(moment(selecteddate).format("YYYY-MM-DD"))
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ"),
        })
      );
    }
  };

  useEffect(() => {
    selecteddate = moment(new Date(), "YYYY-MM")
    window.scroll(0, 0);
    if (isInternetConnected()) {
      dispatch(
        pageDetails({
          offset: 0,
          order: "",
          search: "",
          sort: "",
          current: 1,
        })
      );
      dispatch(
        eventManagementAction({
          admin:true,
          limit: 10,
          offset: 0,
          sort: "",
          search: "",
          order: "",
          // date: "",
          startDate: moment.utc(moment().startOf("month")),
          endDate: moment.utc(moment().endOf("month")),
        })
      );
    } else {
      toast(ValidationConstants.internetCheck, { toastId: "1" });
    }
  }, []);

  return (
    <div>
      <BreadCrumb currentCrumb={appconstant.eventManagement} />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <div className="Event-header">
              <h6 className="text-white text-capitalize ps-3 event-management">
                {appconstant.eventManagement}
              </h6>
              <div className="date-picker">
                <Space direction="vertical">
                  <DatePicker
                    picker="month"
                    onChange={dateChange}
                    className="event-datepicker"
                    inputReadOnly={true}
                    defaultValue={moment(new Date(), "YYYY-MM-DD")}
                  />
                </Space>
              </div>
              <Textfield
                className="iocn-search search-bar"
                placeholder={appconstant.search}
                value={pageDetail?.search}
                onChange={onSearch}
                type="text"
              />
            </div>
          </div>
          <div>
            <button
              onClick={() => navigate(constantRoutes.addEvent)}
              className="button-list"
              type="submit"
            >
              {appconstant.add}
            </button>
          </div>
          <Table
            dataSource={data?.list}
            columns={columns}
            showSorterTooltip={false}
            onChange={tableChanges}
            pagination={{
              total: data?.pagination?.totalCount,
              current: data?.pagination.currentPage,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default EventManagement;

import moment from "moment";
import ApiConstants from "../../themes/apiConstants";

const initialState = {
  onLoad: true,
  userData: null,
  pageDetials: {
    offset: 0,
    order: "",
    search: "",
    sort: "",
    current: 1,
    date: moment.utc(moment().startOf("day")),
    startDate: moment.utc(moment().startOf("month")),
    endDate: moment.utc(moment().endOf("month")),
  },
  postDetail: [],
  data: null,
  viewUser: null,
  eventData: null,
  eventViewData: null,
  interestData: null,
};

function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    ////ResetPassword Reducer......
    case ApiConstants.API_RESET_SUCCESS:
      return {
        ...state,
        onLoad: action?.payload?.onLoad,
        userData: action?.payload?.userData,
      };
    ////ResetPasswordLink reducer...
    case ApiConstants.API_RESETLINK_SUCCESS:
      return {
        ...state,
        onLoad: action.payload.onLoad,
        userData: action.payload.userData,
      };

    //// Page Detail.....
    case ApiConstants.PAGEDETIALS:
      return { ...state, pageDetials: action.payload };

    default:
      return state;
  }
}

export default authenticationReducer;

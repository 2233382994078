import React, { useEffect } from 'react'
import appconstant from '../../themes/appconstant'
import Textfield from '../../common'
import ValidationConstants from '../../themes/validationConstants'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isInternetConnected } from '../../helper/networkUtils'
import { ValidationFunction } from '../../themes/validationFunctions'
import { capitalizeFirstLetter, whitespacesRgx } from '../../utils/validator'
import { constValues } from '../../constants/constantValues'
import { changePassAction } from '../../redux/actions/authActions.js'
import BreadCrumb from '../../common/breadCrumb'

const Changepassword = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = ({ oldPassword, confirmPassword }) => {
    if (isInternetConnected()) {
      dispatch(
        changePassAction({
          oldPassword: oldPassword,
          password: confirmPassword
        })
      )
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 1 })
    }
  }

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.Oldpassword)))
      .required(ValidationFunction.emptyField(appconstant.Oldpassword.toLocaleLowerCase())),
       password: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.NewPassword)))
      .min(constValues.passMinLength, ValidationFunction.minlength(capitalizeFirstLetter(appconstant.NewPassword), constValues.passMinLength))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,ValidationFunction.password(capitalizeFirstLetter(appconstant.NewPassword)))
      .required(ValidationFunction.emptyField(appconstant.NewPassword.toLocaleLowerCase())),
    confirmPassword: Yup.string()
      .matches(whitespacesRgx, ValidationFunction.whitespaces(capitalizeFirstLetter(appconstant.ConfirmNewPassword)))
      .required(ValidationFunction.emptyField(appconstant.ConfirmNewPassword.toLocaleLowerCase()))
      .oneOf(
        [Yup.ref(appconstant.Password.toLocaleLowerCase()), null],
        ValidationConstants.invalidConfirmPassword
      )
  })
  const formik = useFormik({
    initialValues: { oldPassword: '', password: '', confirmPassword: '' },
    validationSchema,
    onSubmit
  })

  return (
    <div>
      <BreadCrumb currentCrumb={appconstant.changepassword} />
      <div
        className='site-layout-background'
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className='content-e'>
          <div className='bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line'>
            <h6 className='text-white text-capitalize ps-3'>
              {appconstant.changepassword}
            </h6>
          </div>
          <br />
          <div className='wrapper_line'>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className='form-group'>
                <label>{appconstant.Oldpassword}</label>
                <Textfield
                  className='form-control'
                  type='password'
                  placeholder='Old Password'
                  onChange={formik.handleChange('oldPassword')}
                  value={formik.values.oldPassword.trim()}
                  error={formik.errors.oldPassword}
                  touched={formik.touched.oldPassword}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='form-group'>
                <label>{appconstant.NewPassword}</label>
                <Textfield
                  className='form-control'
                  type='password'
                  placeholder='New Password'
                  onChange={formik.handleChange('password')}
                  value={formik.values.password.trim()}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className='form-group'>
                <label>{appconstant.ConfirmNewPassword}</label>
                <Textfield
                  className='form-control'
                  type='password'
                  placeholder='Confirm New Password'
                  onChange={formik.handleChange('confirmPassword')}
                  value={formik.values.confirmPassword.trim()}
                  error={formik.errors.confirmPassword}
                  touched={formik.touched.confirmPassword}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='up-cent'>
                <div>
                  <button type='submit' className='button-list login-link'>
                    {appconstant.buttonupate}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Changepassword

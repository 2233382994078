import React from 'react'
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import Changepassword from '../components/changepassword'
import Dashboard from '../components/dashboard'
import ForgotPassword from '../components/forgotpassword'
import Login from '../components/login'
import MainLayout from '../layout/mainlayout'
import EditEventDetails from '../components/eventManagement/editEventDetails'
import EventParticipants from '../components/eventManagement/eventParticipants'
import PostDetails from '../components/postManagement/postDetails'
import PostManagement from '../components/postManagement'
import EditPostDetails from '../components/postManagement/editPostDetails'
import EditUserDetails from '../components/userManagement/editUserDetails'
import UserManagement from '../components/userManagement'
import UserDetails from '../components/userManagement/userDetails'
import EventManagement from '../components/eventManagement'
import EventDetails from '../components/eventManagement/eventDetails'
import AddEvent from '../components/eventManagement/addEvent'
import ResetPassword from '../components/reset'
import { PublicRoute, PrivateRoute } from './screen'
import { constantRoutes } from '../constants/constantRoutes'
import { PageNotFound } from '../common/pageNotFound'
import Eula from '../components/eula'
import { DataManager } from '../helper/dataManager'
import AddMember from '../components/userManagement/addMember'
const RoutesNew = () => {
  let type = DataManager.getUserType()
  return (
    <Routes>
      {/* {PUBLIC ROUTES} */}
      <Route
        path={constantRoutes.astric}
        element={<PageNotFound />}
      />
      <Route
        path={constantRoutes.eula}
        element={<Eula />}
      />

      <Route
        path={constantRoutes.empty}
        element={<Navigate to={constantRoutes.dashboard} />}
      />
      <Route
        path={constantRoutes.other}
        element={<Navigate to={constantRoutes.dashboard} />}
      />
      <Route
        path={constantRoutes.login}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={constantRoutes.forgotpassword}
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path={constantRoutes.resetPassword}
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={constantRoutes.resetPasswordApp}
        element={<ResetPassword />}
      />


      {/*PRIVATE ROUTES*/}
      <Route
        path={constantRoutes.empty}
        element={

          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route
          path={constantRoutes.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {type == "admin" &&(
          <>
      
        <Route
          path={constantRoutes.edituserdetails}
          element={
            <PrivateRoute>
              <EditUserDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.userdetails}
          element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.usermanagement}
          element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          }
        />
         <Route
          path={constantRoutes.postmanagement}
          element={
            <PrivateRoute>
              <PostManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.postdetails}
          element={
            <PrivateRoute>
              <PostDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.editPostDetails}
          element={
            <PrivateRoute>
              <EditPostDetails />
            </PrivateRoute>
          }
        />
        </>
        )}
        
        <Route
          path={constantRoutes.changepassword}
          element={
            <PrivateRoute>
              <Changepassword />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.addEvent}
          element={
            <PrivateRoute>
              <AddEvent />
            </PrivateRoute>
          }
        />
          <Route
          path={constantRoutes.addMember}
          element={
            <PrivateRoute>
              <AddMember />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.editEventDetails}
          element={
            <PrivateRoute>
              <EditEventDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.eventparticipants}
          element={
            <PrivateRoute>
              <EventParticipants />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.eventmanagement}
          element={
            <PrivateRoute>
              <EventManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={constantRoutes.eventdetails}
          element={
            <PrivateRoute>
              <EventDetails />
            </PrivateRoute>
          }
        />
      </Route>



    </Routes>
  )
}
export default RoutesNew

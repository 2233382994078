import ValidationConstants from "./validationConstants";

export const ValidationFunction = {
  password: msg =>`${msg} must include 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.`,
  validField: msg => `Please enter valid ${msg}.`,
  emptyField: msg => `Please enter ${msg}.`,
  minlength: (msg, num = 3) => `${msg} should be at least ${num} characters long.`,
  charLimit: msg => `Can not enter more than ${msg} character.`,
  plzSelect: msg => `Please select ${msg}.`,
  clickToImg: msg => `Click to ${msg} image.`,
  clickToImgVideo: msg => `Click to ${msg} video.`,
  whitespaces: msg => `${msg} should not contain whitespaces.`,
  modalContent: (msg, panel) => `Are you sure, you want to ${msg} this ${panel}?`,
  cityState: (city, state, formikError) => city?.length || !state ? state ? formikError : false : ValidationConstants.NA_city
}
import axios from 'axios'
import { DataManager } from '../../helper/dataManager'

// export const url = 'http://192.168.3.176:5001/' //local
// export const url = 'http://1.6.98.141:3010/' //staging
export const url = 'https://api.frendii.com/' //live

const APIKit = axios.create({
  baseURL: `${url}api/v2/`,
  timeout: 60000000,
  Accept: '*/*'
})

APIKit.interceptors.request.use(async config => {
  const token = DataManager.getAccessToken()
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export default APIKit
